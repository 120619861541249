import api from '../api';
import httpManager from '../httpManager';

// 获取所有中奖记录
export const getWeeklyAllWinningsService = (param) => {
  return httpManager({
    url: api.getAllWinnings,
    method: 'post',
    data: param
  });
};

// 获取中奖期号list
export const getWeeklyLotteryNumService = (param) => {
  return httpManager({
    url: api.getWeeklyLotteryNum,
    method: 'get',
    param
  });
};


// 查询每周抽奖基本信息
export const getWeeklyDrawInfoService = (param) => {
  return httpManager({
    url: api.getWeeklyDrawInfo,
    method: 'get',
    param
  });
};

// 查询每周抽奖用户获奖信息
export const getUserWeeklyLotteryInfoService = (data) => {
  return httpManager({
    url: api.getUserWeeklyLotteryInfo,
    method: 'post',
    data
  });
};