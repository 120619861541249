<script setup>
import { ref } from 'vue'
const isRuleExpended = ref(false)
const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    content: {
        type: String,
        default: ''
    }
})
</script>

<template>
    <div class="container-item">
        <div class="container-item-faq">
            <div class="container-item-faq-item" :class="isRuleExpended ? 'selected' : ''">
                <div class="container-item-faq-item-header" @click="() => {
                    isRuleExpended = !isRuleExpended
                }">
                    <div class="container-item-faq-item-header-title">{{ props.title }}
                    </div>
                    <div class="container-item-faq-item-header-arrow" :class="isRuleExpended ? 'selected' : ''">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="18" viewBox="0 0 8 18" fill="none">
                            <path opacity="0.6" d="M0 0L8 9L0 18" fill="var(--icon-bg-color)" />
                        </svg>
                    </div>
                </div>
                <div class="container-item-faq-item-desc" v-if="isRuleExpended">
                    {{ props.content }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.container-item-faq-item {
    background-color: #222942;
    border-radius: 0.5rem;
    padding: 1.75rem;
    width: calc(100% - 1.75rem * 2);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    cursor: pointer;
}

.container-item-faq-item:hover {
    opacity: 0.9;
}

.container-item-faq-item.selected {
    background-color: #222942;
}

.container-item-faq-item-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.125rem;
}

.container-item-faq-item-header-title {
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
    text-align: left;
    line-height: 1.5rem;
    width: 100%;
}

.container-item-faq-item-header-arrow {
    width: 0.5rem;
    height: 100%;
    min-width: 0.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
}

.container-item-faq-item-header-arrow.selected {
    transform: rotate(90deg);
}

.container-item-faq-item-desc {
    color: #8ea3c3;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
    white-space: pre-line;
}
</style>