const api = {
  // 登录
  authLogin: 'auth/login/',
  // 邮箱验证码注册
  //    authRegistrationConfirmCode:'auth/registration/confirm-code/',
  authRegistrationConfirmCode: 'auth/registration/web/confirm-code/',
  // 邮箱注册发送验证码
  authRegistrationSendCode: 'auth/registration/send-code/',
  // 注册是否强制输入邀请码
  gameGetGameConfig: 'game/get_game_config/',
  // 退出登录
  authLogout: 'auth/logout/',
  // 重置密码
  authRegistrationResetPasswordConfirmCode: 'auth/registration/reset-password-confirm-code/',
  // 重置邮箱登录密码 发送验证码
  authRegistrationResetPasswordSendCode: 'auth/registration/reset-password-send-code/',
  // 用户个人信息
  playeruser: 'playeruser/',
  // 个人wallet钱包(现金账户和资金账户)
  gameGetSimpleTokensInfo: 'game/get_simple_tokens_info/',
  // 充值地址
  gameGetTokensInfo: 'game/get_tokens_info/',
  // 获取单个充值地址（新接口）
  walletGenerateNewAddress: 'wallet/generate_new_address/',
  // 划转
  walletTransfer: 'wallet/transfer/',
  // 提现
  walletWithdrawRequest: 'wallet/withdraw-request/',
  // 提现(无需划转的)
  cryptoWalletWithdrawRequest: 'wallet/crypto-withdraw-request/',
  // 充值记录
  walletGetDepositRecord: 'wallet/get-deposit-record/',
  // 提现记录
  walletGetWithdrawRecord: 'wallet/get-withdraw-record/',
  // telegram登录
  authTelegramRegister: 'auth/telegram/register/',
  // 网页在线人数
  serverStatusOnlineUserNumber: 'server_status/online_user_number/',
  // 排行榜
  serverStatusBiggestToday: 'server_status/biggest_today/',
  // 排行榜
  serverStatusLatestRound: 'server_status/lastest_round/',
  // 游戏详情下注数据 - latest bets
  latestBets: 'latest_bets/',
  // 游戏详情下注数据 - my bets
  myBets: 'mybets/',
  // 推荐人收益
  // referralGetReferralInfo:'referral/get-referral-info/',
  // 推荐人收益
  referralGetReferralInfo: 'openbox/get-referral-info/',
  // 推荐人收益排名
  referralReferralRankings: '/openbox/referral_rankings/',

  // 绑定邀请码
  //    authBindInviteCode:'auth/bind_invite_code/',
  // 绑定邀请码
  authBindInviteCode: 'openbox/bind_invite_code/',
  // 获取游戏url
  openBoxLogin: 'openbox/login/',
  // 获取客服链接
  tosCustomerServiceLink: 'tos/customer-service-link/',
  // 获取首页游戏
  openBoxIndexGames: 'openbox/index_games/',
  // 获取游戏列表
  openBoxOnlineGames: 'openbox/online_games/',
  // 获取游戏最近记录
  openBoxBetHistory: 'openbox/bet_history/',
  // 收藏的游戏列表
  openBoxFavoriteGames: 'openbox/favorite_games/',
  // 收藏游戏
  openBoxFavoriteGame: 'openbox/favorite_game/',
  // 取消收藏游戏
  openBoxUnfavoriteGame: 'openbox/unfavorite_game/',
  // 最近玩过游戏列表
  openBoxRecentGames: 'openbox/recent_games/',
  // 获取casino popular
  openBoxPopularGames: 'openbox/popular_games/',
  // 获取casino new
  openBoxNewGames: 'openbox/new_games/',
  // 获取casino hot
  openBoxHotGames: 'openbox/hot_games/',
  // TG Bot 里快捷授权登录
  authTelegramDisposableLogin: 'auth/telegram/disposableLogin/',
  // 首页游戏搜索
  openboxSearchGames: 'openbox/search_games/',
  // 获取供应商列表
  openboxGetGameProviders: '/openbox/get_game_providers/',
  // 获取首页banner列表
  newapiGetBannerConfig: '/tos/banner-config/',
  // 获取交易记录
  getTransactionsList: '/playeruser/transactions/',
  // 获取大赢家
  getBigWinner: '/game/get_big_winner/',
  // 获取幸运赢家
  getLuckyWinner: '/game/get_lucky_winner/',
  // 参加测试活动
  walletJoinTestActivity: 'wallet/join_test_activity/',
  // 是否参加测试活动
  walletCheckTestActivity: 'wallet/check_test_activity/',
  // 参加空投活动
  walletJoinJoinFreeActivity: 'wallet/join_free_activity/',
  // 是否参加过空投活动
  walletCheckFreeActivity: 'wallet/check_free_activity/',

  // 站内信列表
  // messages:'messages/',
  // 站内信列表
  messages: '/inbox/messages/',
  // 站内信标记已读
  // messagesRead:'messages/read/',
  messagesRead: '/inbox/read/',
  // 客服列表
  tosAvailableCustomerServices: 'tos/available_customer_services/',
  // 更新用户的pixelId
  playerUserUpdateFacebookPixelId: 'playeruser/update_facebook_pixel_id/',
  // 落地页注册
  playerUserCreateAllianceMember: 'playeruser/create_alliance_member/',

  // 获取通知消息
  // getNotifyInfo:'/messages/notify/',
  getNotifyInfo: '/inbox/notify/',

  // 第三方登录绑定邮箱 发送验证码
  player_user_send_bind_email_code: 'playeruser/send_bind_email_code/',
  // 第三方登录绑定邮箱
  player_user_bind_email: 'playeruser/bind_email/',
  // mfa发送邮箱验证码
  send_bind_otp_email_code: 'playeruser/send_bind_otp_email_code/',
  // mfa绑定之前校验邮箱验证码
  vefify_otp_email_code: 'playeruser/vefify_otp_email_code/',
  // mfa绑定
  bind_otp: 'playeruser/bind_otp/',
  // 谷歌验证登录
  googleAuth: '/auth/google/',
  getTosPopupConfig: '/tos/popup-config/',
  // 法币充值获取选择金额列表
  getFiatPreDeposit: '/wallet/fiat_pre_deposit/',
  // 法币充值
  fiat_deposit: '/wallet/fiat_deposit/',
  // 个人投注信息统计查询接口
  getBetInfo: '/playeruser/bet_info/',
  playerUserBetInfo: 'playeruser/bet_info/',
  // 新增提现密码
  add_withdraw_password: '/wallet/add_withdraw_password/',
  // 提现修改密码，发送邮箱验证码
  send_withdraw_password_code: '/wallet/send_withdraw_password_code/',
  // 修改提现密码
  reset_withdraw_password: '/wallet/reset_withdraw_password/',
  // 提现获取已绑定提现账户列表
  fiat_pre_withdraw: '/wallet/fiat_pre_withdraw/',
  // 法币提现，添加银行卡
  add_bank_card: '/wallet/add_bank_card/',
  // 添加肯尼亚银行账户
  add_ken_bank_card: '/wallet/add_ken_account/',
  // 墨西哥提现，添加银行卡
  add_bank_account: '/wallet/add_bank_account/',
  // 法币提现
  fiat_withdraw: '/wallet/fiat_withdraw/',
  // 法币提现列表
  getFiatWithdrawList: '/wallet/withdraw_list/',
  // 获取货币类型加图标
  getTokens: '/tos/get-tokens/',
  // 获取TG引流弹窗数据
  tgDrainageConfig: '/tos/tg-drainage-config/',
  // 获取Google验证分数
  googleVitality: '/tos/google-vitality-for-page/',
  // 获取引流游戏列表
  drainageGameConfig: '/tos/tg-drainage-game-config/',
  // 获取加密货币和本位币价
  getTokensDetail: '/tos/get-tokens-detail/',
  // 活动悬浮窗
  tosGetFloatButtonConfigs: '/tos/get_float_button_configs/',
  // 活动悬浮窗 join
  tosClickFloatButton: '/tos/click_float_button/',
  // 获取bot_id (关于telegram登录所需参数)
  getTGBotId: '/tos/get_bot_id/',
  // kwai 事件上报
  kwaiEvent: '/tos/kwai/event/',
  // 获取余额
  getBalanceTotal: '/tos/get_balance_total/',
  // 获取转盘活动列表
  getTurntableList: '/roulette/activity/activitys/',
  // 获取转盘活动详情
  getTurntableDetail: '/roulette/activity/detail/',
  // 获取转盘抽奖结果
  getTurntableResult: '/roulette/activity/spin/',
  // 获取领奖列表
  getTurntableResultList: '/roulette/activity/get_receive_history/',
  // 转盘提现
  getTurntableReceive: '/roulette/activity/receive/',
  // 获取首页大图
  tosHomeBannerConfig: '/tos/home-banner-config/',
  // 获取首页底部图
  tosBottomBannerConfig: '/tos/bottom-banner-config/',
  // promotion排行
  get_promotions_rank_info: '/tos/get_promotions_rank_info/',
  // get free spin wheel list
  get_free_promotions_for_index: '/tos/get_free_promotions_for_index/',
  // get free spin wheel list
  get_receive_promotions_prize: '/tos/receive_promotions_prize/',
  playerUserRetention: '/playeruser/retention/',
  tosVipQA: 'tos/vip_qa/',
  getAllWinnings: '/tos/query_all_winnings/',
  getWeeklyLotteryNum: '/tos/get_lottery_nums/',
  getWeeklyDrawInfo: '/tos/weekly_lottery_info/',
  getUserWeeklyLotteryInfo: '/tos/user_weekly_lottery_info/'
};

export default api;
