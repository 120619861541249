<script setup>
import { ref, watch } from 'vue';


defineOptions({
    name: 'wg-tab'
})
const props = defineProps({
    tab: {
        type: Array,
        default: () => []
    },
    direction: {
        type: String,
        default: 'vertical'
    },
    title: {
        type: String,
        default: ''
    }
})

const emits = defineEmits(['change'])
const menuData = ref([]);

watch(props.tab, (val) => {
    menuData.value = val.map((item, index) => {
        return {
            isSel: index === 0 ? true : false,
            ...item
        }
    })
}, {
    immediate: true,

})


const menuLinkHandle = (info, index) => {
    emits('change', info, index)
    menuData.value.forEach((item, itemIndex) => {
        if (itemIndex === index) {
            item.isSel = true
        } else {
            item.isSel = false
        }
    })
};
</script>
<template>
    <div>
        <div class="flex justify-between w-full ">
            <div class="flex min-w-[100px] justify-start items-center text-[#fff] font-bold text-[0.75rem]" v-if="props.title">
                {{props.title}}
            </div>
            <div class="flex  gap-2 overflow-auto bg-[#0D101E] w-full md:w-fit">
                <template v-for="(item, index) in menuData">
                    <div :key="item.path" v-if="!item.hidden"
                        class=" text-[#5B7BAB] whitespace-nowrap max-w-full text-ellipsis overflow-hidden text-[1rem] px-[1rem] py-[0.75rem]  cursor-pointer rounded-md md:min-w-[14.625rem] flex-grow  text-center"
                        :class="item.isSel ? 'bg-[#222942] text-shadow-custom' : ''"
                        @click="menuLinkHandle(item, index)">
                        {{ item.name }}
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-[2rem]">
            <template v-for="(item) in menuData">
                <!-- 动态渲染插槽内容 -->
                <template v-if="item.isSel">
                    <component :is="item.id" :key="item.id">
                        <slot :name="item.id"></slot>
                    </component>
                </template>
            </template>
        </div>

    </div>

</template>


<style scoped>
/* @tailwind base; */
</style>