<script setup>
import { onMounted, ref, reactive, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';
import myMenus from '@/pages/menus/myMenus.vue';
import myHeader from '@/components/myHeader.vue';
import myFooter from '@/components/footer/footer.vue';
import WgButton from '@/components/common/wgButton.vue';
import ticketIcon1 from '@/assets/images/weekly-draw/ticket_icon_1.svg'
import ticketIcon2 from '@/assets/images/weekly-draw/ticket_icon_2.svg'
import ticketIcon3 from '@/assets/images/weekly-draw/ticket_icon_3.svg'
import ticketIcon4 from '@/assets/images/weekly-draw/ticket_icon_4.svg'
import ticketIcon5 from '@/assets/images/weekly-draw/ticket_icon_5.svg'
import ticketIcon6 from '@/assets/images/weekly-draw/ticket_icon_6.svg'
import wgTab from './modules/wgTab.vue';
import wgTable from '@/components/common/wgTable/wgTable.vue'
import { getWeeklyAllWinningsService, getWeeklyLotteryNumService, getWeeklyDrawInfoService, getUserWeeklyLotteryInfoService } from '@/assets/network/service/weeklyDraw'
import { getHeaderImg } from '@/assets/tools/avatar.js';
import weekTimeSearch from './modules/weekTimeSearch.vue'
import CountDown from '@/components/turntableDialog/components/CountDown.vue'
import collapsePanel from './modules/collapsePanel.vue';
import { useAppStore } from '@/store/store';
// import { transformedStr } from '@/assets/tools/CommonTool.js';

const appStore = useAppStore()
const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);
const { t } = useI18n();
const weeklyInfo = ref({})
const userAwardsInfo = ref({})
const dateWeekTimeList = ref([])
const isRuleExpended = ref(false)
const resultsLotteryNum = ref('')
const myTicketPastNum = ref('')
const resultsData = ref({})
const AwardsTabType = ref('active')
const pastTale = shallowRef('pastTale')
const resultsTable = shallowRef('resultsTable')
const isHavePromotion = ref(false)

const ticketInfoTab = ref([
    {
        name: t('weekly_draw_010'),
        id: 'myTicket'
    },
    {
        name: t('weekly_draw_011'),
        id: 'Results'
    }
])
let isScrolling = false; // 控制是否正在滚动

const AllTicKetMsgList = ref([])

const AwardsTabList = ref([
    {
        name: t('weekly_draw_023'),
        id: 'active'
    },
    {
        name: t('weekly_draw_024'),
        id: 'past'
    },
    {
        name: t('weekly_draw_025'),
        id: 'win'
    }
])

const tableState = reactive({
    initParams: {
        lottery_num: resultsLotteryNum.value,
    },
    columns: [
        {
            label: 'No.',
            prop: 'no',
            width: '50px'
        },
        {
            label: t('weekly_draw_015').toLocaleUpperCase(),
            tdSlot: 'winner_name',
        },
        {
            label: t('weekly_draw_016').toLocaleUpperCase(),
            tdSlot: 'ticket_number',
            minWidth: '103px'
        },
        {
            label: t('scheduleInfo_016'),
            tdSlot: 'prize',

        }
    ]
});

// 赢家彩票数据请求
const getData = async (params) => {
    // params是从组件接收的，包含分页和搜索字段。
    try {
        const res = await getWeeklyAllWinningsService({
            ...params,
            lottery_num: resultsLotteryNum.value,
        });
        resultsData.value = res.data.data
        // 必须要返回一个对象，包含data数组和total总数
        return {
            data: res.data.data.records,
            total: res.data.data.total_num
        };

    } catch (e) {
        return {
            data: [],
        };
    }
};

const activeTableState = reactive({
    initParams: {
        type: AwardsTabType.value,
    },
    columns: [
        {
            label: t('weekly_draw_027').toLocaleUpperCase(),
            prop: 'ticket_id',
        },
        {
            label: t('Number').toLocaleUpperCase(),
            prop: 'number',
        },
    ]
});


const winnerTableState = reactive({
    initParams: {
        type: AwardsTabType.value,
        lottery_num: myTicketPastNum.value
    },
    columns: [
        {
            label: t('weekly_draw_027').toLocaleUpperCase(),
            prop: 'ticket_id',
        },
        {
            label: t('Number').toLocaleUpperCase(),
            prop: 'number',
        },
        {
            label: 'No.',
            prop: 'awards_level',
        },
        {
            label: t('prize').toLocaleUpperCase(),
            tdSlot: 'prize',
        },
    ]
});


// 赢家彩票数据请求
const getUserWeeklyInfoHandle = async (params) => {

    try {
        const userWeeklyInfoRes = await getUserWeeklyLotteryInfoService({
            ...params,
            type: AwardsTabType.value,
            lottery_num: myTicketPastNum.value
        })
        // 必须要返回一个对象，包含data数组和total总数
        userAwardsInfo.value = userWeeklyInfoRes.data.data
        return {
            data: userWeeklyInfoRes.data.data.ticket_records,

        };

    } catch (e) {
        return {
            data: [],
        };
    }
};



const resultsTableSearchHandle = (val) => {
    resultsLotteryNum.value = val
    resultsTable.value.getTableData()

}

const scrollToTarget = () => {
    const target = document.getElementById('target-section');
    if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
    }
}


const myPastTicketSearchHandle = (val) => {
    myTicketPastNum.value = val
    pastTale.value.getTableData()
}

function startScrolling() {
    if (isScrolling) return; // 避免重复触发
    if (appStore.webSocketData.publicWeekLyLottery.length > 0) {
        isScrolling = true;

        // 取出第一条消息
        AllTicKetMsgList.value = [appStore.webSocketData.publicWeekLyLottery.shift()];

        // 延时后检查是否需要继续滚动
        setTimeout(() => {
            isScrolling = false;
            startScrolling(); // 递归检查继续滚动
        }, 3000);
    } else {
        // 没有消息时，停止滚动并清空显示内容
        AllTicKetMsgList.value = [];
    }
}

watch(() => appStore.webSocketData.publicWeekLyLottery,
    (newList) => {
        // 如果有新数据并且当前未滚动，启动滚动逻辑
        if (newList.length > 0 && !isScrolling) {
            startScrolling();
        }
    },
    {
        deep: true,
        immediate: true, // 确保初始状态下立即检查并启动滚动
    }
)
const init = async () => {
    const weeklyInfoRes = await getWeeklyDrawInfoService()
    if (weeklyInfoRes.data.data) {
        isHavePromotion.value = true
        weeklyInfo.value = weeklyInfoRes.data.data
        const res = await getWeeklyLotteryNumService()
        dateWeekTimeList.value = res.data.data
        resultsLotteryNum.value = dateWeekTimeList.value[1] ? dateWeekTimeList.value[1] : ''
        myTicketPastNum.value = dateWeekTimeList.value[1] ? dateWeekTimeList.value[1] : ''
        const userWeeklyInfoRes = await getUserWeeklyLotteryInfoService({
            type: AwardsTabType.value
        })
        userAwardsInfo.value = userWeeklyInfoRes.data.data
    }
}


onMounted(() => {
    init()
});
</script>

<template>
    <div class="root_div">
        <my-menus></my-menus>
        <div class="right_main max-w-screen">
            <my-header></my-header>
            <template v-if="isHavePromotion">
                <div>
                    <div class="scroll-container">
                        <transition-group name="scroll-fade" tag="div">
                            <div v-for="(msg, index) in AllTicKetMsgList" :key="index" class="scroll-item">
                                <div class="flex items-center text-[1.125rem] ml-[1rem] text-[#FFFFFF] pt-[1rem]">
                                    <img :src="getHeaderImg(msg.avatar)" class="w-[1.5rem] mr-[1rem]" alt="" />
                                    <span class="mr-[0.5rem]">{{ msg.name }}</span>
                                    <span>{{ t('weekly_draw_030').replaceAll('****', msg.count) }}</span>
                                </div>
                            </div>
                        </transition-group>
                    </div>
                    <div class="header">
                        <img class="header-image" :src="weeklyInfo?.rule?.image" />
                        <div :class="'header-content ' + (matchSM ? 'matchSM' : '')">
                            <div class="main-content" style="background-color: transparent; padding-top: 0">
                                <div class="body" style="background-color: transparent">
                                    <div :class="'header-container ' + (matchSM ? 'matchSM' : '')">
                                        <div :class="'header-container-title ' + (matchSM ? 'matchSM' : '')">
                                            {{ t('weekly_draw_002') }}
                                        </div>
                                        <div
                                            :class="'text-[3.62rem] text-[#fff] font-bold' + (matchSM ? 'matchSM' : '')">
                                            {{ weeklyInfo.symbol }} {{ Number(weeklyInfo.total_prize).toFixed(2) }}
                                        </div>
                                        <div class="text-[1.125rem] text-[#fff] flex">
                                            <span>{{ t('weekly_draw_003') }}</span>
                                            <CountDown class="ml-[0.5rem]" :targetTimestamp="weeklyInfo.next_draw_time">
                                            </CountDown>
                                        </div>
                                        <WgButton class="!py-[0.69rem] md:!py-0" @click="scrollToTarget">
                                            {{ t('weekly_draw_004') }}
                                        </WgButton>
                                        <div class="text-[#AAC4ED] text-[1.125rem]">
                                            [{{ weeklyInfo.ticket_count }}] {{ t('weekly_draw_031') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-[1rem]">
                        <div class="bg-[#191F33] rounded-md  mt-[0.88rem] p-[1.5rem]">
                            <div class="text-[0.75rem] text-[#fff] font-bold text-left">
                                {{ t('weekly_draw_005') }}
                            </div>
                            <div class="mt-[1rem] flex gap-[1rem] flex-wrap">
                                <div
                                    class="bg-[#2C394E] rounded-md p-[1rem] flex items-center flex-grow md:min-w-[33rem]">
                                    <div
                                        class="bg-[#2C394E] flex justify-center items-center rounded-full w-[3.75rem] h-[3.75rem]">
                                        <img :src="ticketIcon1" alt="" class="w-[2.625rem]">
                                    </div>
                                    <div class="text-left">
                                        <div class="text-[0.875rem] text-[#fff] font-bold">
                                            {{ t('weekly_draw_007').replace('****', weeklyInfo.symbol
                                                + ' ' + Number(weeklyInfo.daily_amount).toFixed(2)) }}
                                        </div>
                                        <div class="text-[#AAC4ED] text-[0.75rem] mt-[0.5rem]">
                                            +{{ weeklyInfo.daily_num }} {{ t('weekly_draw_008') }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="bg-[#2C394E] rounded-md p-[1rem] flex items-center  flex-grow md:min-w-[33rem]">
                                    <div
                                        class="bg-[#2C394E] flex justify-center items-center rounded-full w-[3.75rem] h-[3.75rem]">
                                        <img :src="ticketIcon2" alt="" class="w-[2.625rem]">
                                    </div>
                                    <div class="text-left">
                                        <div class="text-[0.875rem] text-[#fff] font-bold">{{ t('weekly_draw_009') }}
                                            {{ weeklyInfo.symbol }} {{ Number(weeklyInfo.weekly_amount).toFixed(2) }}
                                        </div>
                                        <div class="text-[#AAC4ED] text-[0.75rem] mt-[0.5rem]">
                                            +{{ weeklyInfo.weekly_num }} {{ t('weekly_draw_008') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <wgTab :tab="ticketInfoTab" direction="horizontal" class="mt-[0.88rem]">
                            <template #myTicket>
                                <div>
                                    <div class="bg-[#191F33] p-[1.5rem] flex flex-wrap justify-between gap-[1rem]">
                                        <div class="flex items-center px-[1rem]">
                                            <img class="w-[1.5rem]" :src="ticketIcon3" alt="">
                                            <div class="ml-[1.56rem] text-left">
                                                <div class="text-[0.75rem] font-bold text-[#fff]">
                                                    {{ t('weekly_draw_012').toLocaleUpperCase() }}
                                                </div>
                                                <div class="text-[1.125rem] text-[#fff]">
                                                    {{ userAwardsInfo.total_tickets }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex items-center px-[1rem] =">
                                            <img class="w-[1.5rem]" :src="ticketIcon4" alt="">
                                            <div class="ml-[1.56rem] text-left">
                                                <div class="text-[0.75rem] font-bold text-[#fff]">
                                                    {{ t('weekly_draw_013').toLocaleUpperCase() }}
                                                </div>
                                                <div class="text-[1.125rem] text-[#fff]">
                                                    {{ userAwardsInfo.total_winning_tickets }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex items-center px-[1rem]">
                                            <img class="w-[1.5rem]" :src="ticketIcon5" alt="">
                                            <div class="ml-[1.56rem] text-left">
                                                <div class="text-[0.75rem] font-bold text-[#fff]">
                                                    {{ t('weekly_draw_014').toLocaleUpperCase() }}
                                                </div>
                                                <div class="text-[1.125rem] text-[#fff]">
                                                    {{ weeklyInfo.symbol }}
                                                    {{ Number(userAwardsInfo.total_prize_won).toFixed(2) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-[0.88rem]">
                                        <wgTab :tab="AwardsTabList" direction="horizontal" class="mt-[0.88rem]"
                                            :title="t('weekly_draw_026').toLocaleUpperCase()" @change="(val) => {

                                                AwardsTabType = val.id
                                            }">
                                            <template #active>
                                                <div>
                                                    <wgTable class="mt-[1rem]" :request="getUserWeeklyInfoHandle"
                                                        :hideTitleBar="true" :columns="activeTableState.columns"
                                                        :initParams="activeTableState.initParams" :pagination="false">
                                                    </wgTable>
                                                </div>
                                            </template>
                                            <template #past>
                                                <div class="">
                                                    <div class="ml-[1.5rem]  flex justify-end">
                                                        <weekTimeSearch :value="myTicketPastNum"
                                                            :list="dateWeekTimeList" @change="myPastTicketSearchHandle">
                                                        </weekTimeSearch>
                                                    </div>
                                                    <wgTable class="mt-[1rem]" :request="getUserWeeklyInfoHandle"
                                                        :hideTitleBar="true" :columns="activeTableState.columns"
                                                        :initParams="activeTableState.initParams" ref="pastTale"
                                                        :pagination="false">
                                                    </wgTable>
                                                </div>
                                            </template>
                                            <template #win>
                                                <div>
                                                    <wgTable class="mt-[1rem]" :request="getUserWeeklyInfoHandle"
                                                        :hideTitleBar="true" :columns="winnerTableState.columns"
                                                        :initParams="winnerTableState.initParams" :pagination="false">
                                                        <template #prize="{ row }">
                                                            <div class="win">
                                                                {{ row.symbol }}{{ Number(row.prize).toFixed(2) }}
                                                            </div>
                                                        </template>
                                                    </wgTable>
                                                </div>
                                            </template>
                                        </wgTab>
                                    </div>
                                </div>

                            </template>
                            <template #Results>
                                <div>
                                    <div class="flex flex-wrap justify-between items-center">
                                        <div class="text-[0.75rem] font-bold text-[#fff]">
                                            {{ t('weekly_draw_017').toLocaleUpperCase() }}
                                        </div>
                                        <div class="flex items-center">
                                            <div class="text-[0.875rem] text-[#5B7BAB] font-bold">
                                                {{ t('weekly_draw_018') }}
                                            </div>
                                            <div class="ml-[1.5rem]">
                                                <weekTimeSearch :value="resultsLotteryNum" :list="dateWeekTimeList"
                                                    @change="resultsTableSearchHandle"></weekTimeSearch>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="text-[#5B7BAB] text-[0.875rem] flex justify-between flex-wrap items-center mt-[0.5rem]">
                                        <div>
                                            {{ t('weekly_draw_020') }}: {{ resultsLotteryNum }}
                                        </div>
                                        <div>
                                            {{ t('weekly_draw_021') }}: {{ resultsData.total_count }}
                                        </div>
                                    </div>
                                    <div class="overflow-x-auto ">
                                        <wgTable class="mt-[1rem]" ref="resultsTable" :request="getData"
                                            :hideTitleBar="true" :columns="tableState.columns"
                                            :initParams="tableState.initParams">
                                            <template #winner_name="{ row }">
                                                <div class="flex items-center ">
                                                    <img :src="getHeaderImg(row.avatar)"
                                                        class="w-[1.25rem] h-[1.25rem]" />
                                                    <div class="ml-[0.88rem] text-ellipsis overflow-hidden truncate">
                                                        {{ row.winner_name }}
                                                    </div>
                                                </div>
                                            </template>
                                            <template #ticket_number="{ row }">
                                                <div class='flex items-center pl-[10px] text-[#0D101E] text-[0.875rem] w-[7.4rem] h-[1.7rem] text-right  justify-center'
                                                    :style="{ backgroundImage: `url(${ticketIcon6})` }">
                                                    {{ row.ticket_number }}
                                                </div>
                                            </template>
                                            <template #prize="{ row }">
                                                <div>
                                                    +{{ row.symbol }} {{ Number(row.prize).toFixed(2) }}
                                                </div>
                                            </template>
                                        </wgTable>
                                    </div>

                                </div>
                            </template>
                        </wgTab>
                        <div id="target-section" class="mt-[1.5rem]">
                            <div class="container-item">
                                <div class="container-item-faq">
                                    <div class="container-item-faq-item" :class="isRuleExpended ? 'selected' : ''">
                                        <div class="container-item-faq-item-header" @click="() => {
                                            isRuleExpended = !isRuleExpended
                                        }">
                                            <div class="container-item-faq-item-header-title">{{ t('weekly_draw_028') }}
                                            </div>
                                            <div class="container-item-faq-item-header-arrow"
                                                :class="isRuleExpended ? 'selected' : ''">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="18"
                                                    viewBox="0 0 8 18" fill="none">
                                                    <path opacity="0.6" d="M0 0L8 9L0 18" fill="var(--icon-bg-color)" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="container-item-faq-item-desc" v-if="isRuleExpended"
                                            v-html="weeklyInfo?.rule?.content">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-[1.5rem]">
                            <div class="text-[0.75rem] text-left text-[#fff] font-bold">
                                {{ t('weekly_draw_029') }}
                            </div>
                            <div class="mt-[1.5rem]">
                                <div v-for="(item, index) in weeklyInfo.qa_configs" :key="index" class="mt-[0.8rem]">
                                    <collapsePanel :title="item.question" :content="item.answer"></collapsePanel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </template>
            <template v-else>
                <div class="flex flex-col justify-center items-center gap-[40px] h-[60vh]">
                    <img class="w-[300px] block" src="@/assets/images/home/web_logo.svg" alt="" />
                    <div class="text-[#ffffff] text-[22px] font-[600]">{{ t('unopened_tips') }}</div>
                </div>
            </template>

            <my-footer :isHomeFooter="false"></my-footer>
        </div>
    </div>
</template>

<style scoped>
.header {
    position: relative;
    width: 100%;
    min-height: 16rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 3rem 1.5rem;
}

.header-content.matchSM {
    padding: 1.75rem 0.5rem;
}

.header-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.75rem;
    max-width: 46.75rem;
}

.header-container.matchSM {
    gap: 1.125rem;
    max-width: 100%;
}

.header-container-title {
    color: #ADEDFF;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 2.28rem;
    text-align: left;
    white-space: pre-line;
}

.header-container-title.matchSM {
    font-size: 1.125rem;
    line-height: 1.375rem;
}

.container-item-faq-item {
    background-color: #222942;
    border-radius: 0.5rem;
    padding: 1.75rem;
    width: calc(100% - 1.75rem * 2);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    cursor: pointer;
}

.container-item-faq-item:hover {
    opacity: 0.9;
}

.container-item-faq-item.selected {
    background-color: #222942;
}

.container-item-faq-item-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.125rem;
}

.container-item-faq-item-header-title {
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
    text-align: left;
    line-height: 1.5rem;
    width: 100%;
}

.container-item-faq-item-header-arrow {
    width: 0.5rem;
    height: 100%;
    min-width: 0.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
}

.container-item-faq-item-header-arrow.selected {
    transform: rotate(90deg);
}

.container-item-faq-item-desc {
    color: #8ea3c3;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
    white-space: pre-line;
}

.scroll-container {
    position: relative;
    overflow: hidden;
    height: 4rem;
    /* 容器固定高度 */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.scroll-item {
    animation: scroll-fade-in-out 3s ease-in-out infinite;
    /* 使用平滑的动画效果 */
    white-space: nowrap;
    display: block;
    /* 确保内容正确渲染 */
}

@keyframes scroll-fade-in-out {
    0% {
        opacity: 0;
        transform: translateY(100%);
        /* 内容从屏幕下方开始 */
    }

    25% {
        opacity: 1;
        transform: translateY(0);
        /* 移动到可见区域 */
    }

    75% {
        opacity: 1;
        transform: translateY(0);
        /* 停留在可见区域 */
    }

    100% {
        opacity: 0;
        transform: translateY(-100%);
        /* 内容淡出并移出屏幕顶部 */
    }
}
</style>
