<template>
  <div>
    <!-- title 和 工具栏 -->
    <div class="flex" v-if="!hideTitleBar">
      <slot name="title">
        <span class="title text-left text-[#fff] font-bold text-[0.75rem]">{{ title }}</span>
      </slot>
      <div class="toolbar">
        <slot name="toolbar"></slot>
      </div>
    </div>
    <!-- 搜索选项 -->
    <el-form v-if="!!search" class="flex flex-wrap mt-[1.78rem] gap-1" :model="searchModel" :inline="true"
      label-position="left" ref="searchForm">
      <el-form-item v-for="item in search.fields" :key="item.name" :label="item.label" :prop="item.name">
        <slot v-if="item.type === 'custom'" :name="item.slot" />
        <el-select v-else-if="item.type === 'select'" v-model="searchModel[item.name]" :filterable="!!item.filterable"
          :multiple="!!item.multiple" size="large" :style="'width:' + item.width" :empty-values="[null, undefined]">
          <el-option v-for="option of item.options" :key="option.value" :label="option.name"
            :value="option.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!-- table表格栏 -->
    <div class="overflow-x-auto max-w-full border-content">
      <el-table v-loading="loading" :data="tableData" :row-key="rowKey" :tree-props="tree.treeProps" :lazy="tree.lazy"
        :load="tree.load" tooltip-effect="dark" :border="border" @selection-change="handleSelectionChange" ref="table"
        header-row-class-name="table-header">
        <template #empty>
          <div class="py-[6.25rem]">
            <div>
              <svg viewBox="0 0 89 20" class="empty-img" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.05851 0.00900537C2.10943 0.00900537 1.15603 0.00900537 0.206942 0.00900537C-0.0130727 0.00900537 -0.0475849 0.0533117 0.0559516 0.25269C0.224198 0.576126 0.409701 0.88627 0.59089 1.20528C2.35533 4.32001 4.11976 7.43917 5.8842 10.5539C7.63137 13.6376 9.37424 16.7258 11.1171 19.8095C11.1646 19.8892 11.2034 19.9956 11.3069 20C11.4191 20 11.4493 19.8892 11.4924 19.8095C12.256 18.4537 13.0196 17.0935 13.7875 15.7422C14.7797 13.9876 15.7806 12.2375 16.7685 10.483C16.8332 10.3678 16.9799 10.2349 16.9108 10.1197C16.8461 10.0134 16.6736 10.1286 16.5485 10.1064C16.5139 10.102 16.4751 10.1064 16.4406 10.1064C15.2111 10.0975 13.9816 10.1241 12.7521 10.0798C12.0058 10.0532 11.4795 9.71208 11.1559 9.0209C11.0093 8.71076 10.8626 8.40062 10.6943 8.10376C9.21031 5.47197 7.71765 2.84461 6.23363 0.212814C6.14735 0.0621729 6.0395 0.000144115 5.87125 0.00457474C4.93511 0.00900537 3.99897 0.00457474 3.05851 0.00457474V0.00900537Z" />
                <path
                  d="M29.991 0.00900537C29.0979 0.00900537 28.2006 0.0222973 27.3076 0.000144115C27.0315 -0.00428651 26.8805 0.0931873 26.7511 0.328011C26.1989 1.32933 25.6295 2.31736 25.0643 3.31425C23.0022 6.95623 20.9488 10.6026 18.878 14.2402C18.7012 14.5503 18.7098 14.794 18.878 15.0909C19.7279 16.5751 20.5734 18.0638 21.406 19.5569C21.6261 19.9513 21.8245 20.0266 22.0877 19.5569C23.5458 16.9517 25.0169 14.3554 26.488 11.759C28.3646 8.44049 30.2455 5.12195 32.1221 1.80341C32.4068 1.30275 32.6915 0.806518 32.9676 0.301427C33.1014 0.0577423 33.0668 0.013436 32.7951 0.013436C31.8589 0.013436 30.9228 0.013436 29.9823 0.013436L29.991 0.00900537Z" />
                <path
                  d="M15.5692 7.14232C16.5183 7.14232 17.4717 7.14232 18.4207 7.14232C18.5804 7.14232 18.6753 7.09801 18.7616 6.94737C19.702 5.27259 20.6554 3.60668 21.6045 1.93633C21.8935 1.42238 22.1869 0.912854 22.4716 0.39447C22.6614 0.0444504 22.6312 0.00457474 22.2473 0.00457474C20.4397 0.00457474 18.6278 0.00900537 16.8202 0.000144115C16.5657 0.000144115 16.432 0.0887567 16.3112 0.305857C15.677 1.44453 15.0342 2.57434 14.3914 3.70858C13.8004 4.75421 13.2094 5.79984 12.627 6.84546C12.5796 6.92965 12.4458 7.00054 12.5105 7.10244C12.5666 7.18662 12.696 7.13789 12.7953 7.13789C13.7185 7.13789 14.6417 7.13789 15.5605 7.13789L15.5692 7.14232Z" />
                <path
                  d="M71.623 7.93085C71.7845 8.09243 71.8204 8.27645 71.8788 8.447C72.552 10.4263 73.2253 12.4102 73.8851 14.394C73.9883 14.7082 74.1319 14.8652 74.4461 14.8428C74.7782 14.8204 75.1193 14.8293 75.4515 14.8428C75.7073 14.8518 75.8464 14.7441 75.9362 14.4703C76.6229 12.4506 77.3186 10.4353 78.0143 8.41558C78.0457 8.32133 78.0816 8.23156 78.122 8.1418C78.1534 8.07896 78.2028 8.01613 78.2746 8.05652C78.3105 8.07447 78.3329 8.14629 78.3374 8.19566C78.3464 8.2944 78.3374 8.39314 78.3374 8.49188C78.3374 10.4533 78.3374 12.4102 78.324 14.3715C78.324 14.7261 78.4586 14.8563 78.7683 14.8428C79.1408 14.8293 79.5179 14.8293 79.8904 14.8428C80.2046 14.8563 80.3302 14.7082 80.3302 14.3626C80.3213 11.4093 80.3168 8.45598 80.3168 5.50269C80.3168 5.16158 80.2046 5.01795 79.8859 5.02244C79.1004 5.03591 78.3105 5.04039 77.5251 5.02244C77.2154 5.01346 77.0628 5.14811 76.9595 5.46678C76.3761 7.28902 75.7791 9.10229 75.1822 10.9156C75.1373 11.0502 75.1597 11.2791 74.9757 11.2701C74.8411 11.2612 74.8366 11.0547 74.7917 10.9245C74.1858 9.0978 73.5754 7.27556 72.9784 5.44434C72.8797 5.14363 72.7361 5.01346 72.4443 5.02244C71.6319 5.03591 70.8196 5.03591 70.0072 5.02244C69.7065 5.02244 69.5853 5.1526 69.5987 5.48025C69.6212 6.10412 69.6481 6.72799 69.6436 7.35186C69.6302 9.66781 69.702 11.9793 69.5987 14.2907C69.5808 14.6947 69.6975 14.8697 70.079 14.8428C70.4021 14.8159 70.7253 14.8428 71.0485 14.8383C71.5377 14.8383 71.5512 14.8204 71.5556 14.2907C71.5556 12.3742 71.5556 10.4578 71.5601 8.54126C71.5601 8.34826 71.5108 8.14629 71.6364 7.93534L71.623 7.93085Z" />
                <path
                  d="M38.8361 10.1481H38.845C38.845 11.5888 38.845 13.0295 38.845 14.4703C38.845 14.955 38.8765 14.9864 39.3387 14.9909C39.6484 14.9909 39.9581 14.9909 40.2678 14.9909C40.8603 14.9909 40.8737 14.9775 40.8737 14.385C40.8737 12.5179 40.8737 10.6552 40.8782 8.78811C40.8782 8.68488 40.8289 8.5233 40.9815 8.49637C41.0937 8.47842 41.1206 8.62653 41.17 8.71181C42.1709 10.6283 43.2705 12.4909 44.3207 14.3805C44.4464 14.6049 44.608 14.6857 44.8548 14.6812C45.6807 14.6588 46.5065 14.6408 47.3324 14.6274C47.5972 14.6274 47.7004 14.5062 47.7004 14.2504C47.7004 11.2926 47.7004 8.33479 47.7004 5.37702C47.7004 5.11221 47.5882 5.00449 47.3279 5.01346C47.0317 5.02244 46.7354 5.01346 46.4392 5.01346C45.748 5.01346 45.7211 5.01346 45.757 5.72261C45.8512 7.66604 45.757 9.60498 45.757 11.5484C45.757 11.6606 45.8019 11.8177 45.6672 11.8536C45.5101 11.894 45.5191 11.71 45.4697 11.6202C44.3791 9.60498 43.2884 7.59423 42.2023 5.5745C42.0945 5.37253 41.9375 5.32316 41.7265 5.32316C40.9411 5.32764 40.1511 5.32316 39.3657 5.32316C38.8854 5.32316 38.854 5.35009 38.854 5.82584C38.854 7.26658 38.854 8.70732 38.854 10.1481H38.8361Z" />
                <path
                  d="M53.6609 14.9909C54.4104 14.9909 55.1555 14.9954 55.905 14.9909C56.7129 14.9819 57.2784 14.4882 57.431 13.6489C57.4894 13.3392 57.5118 13.0251 57.5298 12.7109C57.5836 11.5753 57.4849 10.4353 57.5746 9.29977C57.5971 9.02599 57.4579 8.97213 57.247 8.97213C56.0082 8.97213 54.7695 8.97213 53.5307 8.97213C53.2659 8.97213 53.1537 9.09331 53.1582 9.37159C53.1627 9.79797 53.1671 10.2199 53.1582 10.6463C53.1492 10.956 53.2883 11.0592 53.5576 11.0502C53.9302 11.0367 54.3072 11.0502 54.6797 11.0502C55.6177 11.0502 55.712 11.1714 55.5414 12.1768C55.4472 12.7198 55.2228 12.9263 54.6976 12.9263C54.0379 12.9263 53.3781 12.8859 52.7228 12.9353C51.7803 13.0071 51.2776 12.3204 51.2686 11.4766C51.2596 10.6014 51.2596 9.72167 51.2776 8.84646C51.2821 8.59063 51.3449 8.33031 51.4122 8.07896C51.5918 7.41021 51.9733 7.105 52.6241 7.10052C53.2569 7.09603 53.8898 7.10052 54.5226 7.10052C55.2407 7.10052 55.3574 7.18131 55.5459 7.92636C55.6088 8.1777 55.7344 8.25401 55.9589 8.25401C56.3852 8.24952 56.8116 8.24054 57.238 8.25401C57.5387 8.26298 57.624 8.11038 57.6016 7.82313C57.5746 7.48202 57.5567 7.14091 57.5387 6.7998C57.4894 5.78545 56.8027 5.04488 55.8466 5.03591C54.4014 5.01795 52.9562 5.01346 51.511 5.03591C50.4024 5.05386 49.7112 5.68222 49.4553 6.83122C49.4239 6.97933 49.3925 7.12745 49.3745 7.27556C49.2219 8.55921 49.3386 9.85183 49.3027 11.1355C49.2848 11.7953 49.3521 12.4505 49.4508 13.0969C49.6304 14.2863 50.4069 14.9909 51.5289 14.9999C52.2381 14.9999 52.9472 14.9999 53.6564 14.9999L53.6609 14.9909Z" />
                <path
                  d="M81.8338 9.96404C81.8338 11.4766 81.8383 12.9936 81.8293 14.5062C81.8293 14.8652 81.946 15.0044 82.3006 14.9999C83.4496 14.9864 84.5986 14.9954 85.7476 14.9909C86.6901 14.9909 87.6327 15.0044 88.5707 14.9415C88.8625 14.9236 88.9926 14.8159 88.9836 14.5062C88.9702 14.1741 88.9836 13.8419 88.9836 13.5098C88.9836 13.0161 88.9567 12.9847 88.4854 12.9847C87.0806 12.9847 85.6713 12.9757 84.2665 12.9847C83.9298 12.9847 83.7952 12.8455 83.7997 12.5179C83.7997 12.1453 83.7952 11.7728 83.7952 11.4003C83.7952 10.8797 83.8356 10.8437 84.3517 10.8393C85.6399 10.8258 86.9325 10.8707 88.2206 10.7944C88.5438 10.7764 88.6919 10.6642 88.674 10.3186C88.656 9.99995 88.674 9.68128 88.674 9.36261C88.674 8.8689 88.647 8.83748 88.1757 8.83299C86.9998 8.83299 85.8284 8.83299 84.6524 8.83299C83.7907 8.83299 83.7952 8.83299 83.7952 7.93534C83.7952 6.87161 83.7952 7.0287 84.7063 7.01524C85.9855 6.99729 87.2601 7.04666 88.5393 6.97036C88.8714 6.9524 89.0016 6.82673 88.9836 6.48562C88.9657 6.15349 88.9702 5.82136 88.9881 5.48922C89.0106 5.12567 88.8714 5 88.5169 5C86.4388 5.01346 84.3607 5.01346 82.2826 5C81.9191 5 81.8159 5.13465 81.8203 5.49371C81.8338 6.97933 81.8248 8.46944 81.8248 9.95506L81.8338 9.96404Z" />
                <path
                  d="M35.0524 10.1346C35.0524 11.5708 35.0524 13.0026 35.0524 14.4389C35.0524 14.9685 35.0839 14.9954 35.609 14.9954C35.8783 14.9954 36.1521 14.9999 36.4214 14.9909C36.8792 14.973 36.9555 14.8877 36.9555 14.4164C36.9555 12.0107 36.951 9.60947 36.9555 7.20375C36.9555 6.71452 36.9824 6.22081 37.0048 5.73159C37.0183 5.44434 36.8971 5.31867 36.6144 5.32764C36.2553 5.33662 35.8917 5.35009 35.5327 5.32764C35.1467 5.29174 35.0435 5.45332 35.0435 5.83033C35.0614 7.26658 35.0479 8.69835 35.0524 10.1346Z" />
                <path
                  d="M68.2119 14.192C67.1392 11.2432 66.0575 8.29889 64.9848 5.35009C64.895 5.10323 64.7469 5.00898 64.4911 5.01795C64.132 5.02693 63.7685 5.01795 63.4094 5.01795C63.0863 5.01795 62.7631 5.04039 62.4444 5.01346C62.0854 4.98205 61.9103 5.12567 61.7936 5.46229C61.3762 6.66515 60.9319 7.85904 60.501 9.05741C59.8906 10.7495 59.2802 12.4461 58.6743 14.1426C58.5127 14.5959 58.5845 14.6947 59.0737 14.6857C59.536 14.6767 60.0028 14.6453 60.4651 14.6363C60.7075 14.6363 60.8421 14.5196 60.9184 14.2952C61.0396 13.9272 61.1922 13.5726 61.2909 13.2001C61.3583 12.9443 61.4929 12.8321 61.7487 12.8321C62.8573 12.8321 63.966 12.8321 65.079 12.8321C65.3349 12.8321 65.4875 12.9443 65.5683 13.1821C65.7074 13.5816 65.869 13.9766 65.9991 14.3805C66.071 14.6004 66.2011 14.6902 66.4255 14.6857C66.9013 14.6812 67.3815 14.6857 67.8573 14.6857C68.2792 14.6812 68.351 14.587 68.2074 14.1965L68.2119 14.192ZM64.3609 10.8886C64.0512 10.8752 63.7415 10.8886 63.4363 10.8886C63.1401 10.8886 62.8439 10.8752 62.5477 10.8886C62.2245 10.9066 62.1706 10.7719 62.2739 10.4981C62.5656 9.73065 62.8439 8.95867 63.1311 8.18668C63.1491 8.13731 63.1626 8.08794 63.1895 8.04306C63.2972 7.88148 63.2029 7.54037 63.4588 7.55832C63.6473 7.57179 63.6428 7.89045 63.7146 8.07447C64.0198 8.87788 64.3116 9.68577 64.6212 10.4892C64.729 10.7674 64.6931 10.8976 64.3609 10.8841V10.8886Z" />
              </svg>
              <!-- <img class="empty-img" src="../../../assets/images/home/web_logo.svg" style="width: 13.6875rem; height: 4.375rem" /> -->
            </div>
            <div class="text-[#5B7BAB] text-[1.125rem]">
              {{ t('no_data') }}
            </div>
          </div>
        </template>
        <el-table-column v-for="item in columns" :key="item.label" :filter-method="item.filters && filterHandler"
          :show-overflow-tooltip="!item.wrap" v-bind="item">
          <template #header="scope" v-if="!!item.labelSlot">
            <slot :name="item.labelSlot" v-bind="scope"></slot>
          </template>
          <template #default="scope" v-if="!!item.tdSlot || !!item.copy">
            <slot v-if="!!item.tdSlot" :name="item.tdSlot" v-bind="scope"></slot>
            <div v-else class="flex">
              <div class="w-5/6 overflow-hidden text-ellipsis">
                {{ scope.row[item.prop] }}
              </div>

              <span class="iconfont icon-bntn_ContentCopyFilled cursor-pointer !text-[24px]" @click="() => {
                  copy(scope.row[item.prop]);
                  toast($t('copy_successfully'), { autoClose: 1000 });
                }
                "></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <el-pagination v-if="paginationConfig.show && total > 0" class="pagination" :style="paginationConfig.style"
      @size-change="handleSizeChange" v-model:currentPage="pageNum" @current-change="handleCurrentChange"
      :page-sizes="paginationConfig.pageSizes" v-model:pageSize="pageSize" :layout="paginationConfig.layout" background
      :total="total">
      <template #default>
        <div class="custom-select-container">
          <el-select v-model="pageSize" size="mini" class="custom-page-size-select" @change="handleSizeChange">
            <el-option v-for="size in paginationConfig.pageSizes" :key="size" :label="size" :value="size" />
          </el-select>
        </div>
      </template>
    </el-pagination>
  </div>
</template>
<script setup name="wgTable">
import { reactive, toRefs, onBeforeMount, watch } from 'vue';
import { useClipboard } from '@vueuse/core';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';

const { copy } = useClipboard({ legacy: true });
const { t } = useI18n();

const getSearchModel = (search) => {
  const searchModel = {};
  if (search && search.fields) {
    search.fields.forEach((item) => {
      if (item.defaultValue !== undefined) {
        searchModel[item.name] = item.defaultValue;
        if ((item.type === 'daterange' || item.type === 'datetimerange') && !!item.trueNames && Array.isArray(item.defaultValue)) {
          item.defaultValue.forEach((val, index) => {
            searchModel[item.trueNames[index]] = val;
          });
        }
      }
    });
  }
  return searchModel;
};

// Props
const props = defineProps({
  request: Function,
  initParams: {
    type: Object,
    default: () => { }
  },
  title: {
    type: String,
    default: ''
  },
  hideTitleBar: {
    type: Boolean,
    default: false
  },
  search: {
    type: [Boolean, Object],
    default: false
  },
  border: {
    type: Boolean,
    default: false
  },
  columns: {
    type: Array,
    default: () => []
  },
  rowKey: {
    type: String,
    default: 'id'
  },
  pagination: {
    type: [Boolean, Object],
    default: () => ({})
  },
  tree: {
    type: Object,
    default: () => ({})
  }
});

// Emit
const emit = defineEmits(['selectionChange']);

// State
const state = reactive({
  searchModel: getSearchModel(props.search),
  loading: false,
  tableData: [],
  total: 0,
  pageNum: 1,
  pageSize: (props.pagination && props.pagination.pageSize) || 10,
  paginationConfig: {
    show: false
  },
  handleSearch() {
    state.pageNum = 1;
    getTableData();
  },
  handleReset() {
    if (JSON.stringify(state.searchModel) === '{}') {
      return;
    }
    state.pageNum = 1;
    state.searchModel = getSearchModel(props.search);
    getTableData();
  },
  refresh() {
    getTableData();
  },
  handleCurrentChange() {
    getTableData();
  },
  handleSizeChange() {
    state.pageNum = 1;
    getTableData();
  },
  handleSelectionChange(arr) {
    emit('selectionChange', arr);
  },
  filterHandler(value, row, column) {
    const property = column['property'];
    return row[property] === value;
  }
});

// Watch
watch(state.searchModel, () => {
  getTableData();
});

// Pagination configuration
if (typeof props.pagination === 'object') {
  const { layout, pageSizes, style } = props.pagination;
  state.paginationConfig = {
    show: true,
    background: true,
    layout: layout || 'sizes, prev, pager, next',
    pageSizes: pageSizes || [10, 20, 30, 40, 50, 100],
    prevText: 'Previous',
    nextText: 'Next',
    style: style || {}
  };
}

// Optimizing search fields
const optimizeFields = (search) => {
  const searchModel = JSON.parse(JSON.stringify(state.searchModel));
  if (search && search.fields) {
    search.fields.forEach((item) => {
      if (!Object.prototype.hasOwnProperty.call(searchModel, item.name)) {
        return;
      }
      if (item.transform) {
        searchModel[item.name] = item.transform(searchModel[item.name]);
      }
      if ((item.type === 'daterange' || item.type === 'datetimerange') && !!item.trueNames) {
        delete searchModel[item.name];
      }
    });
  }
  return searchModel;
};

// Fetch table data
const getTableData = async () => {
  state.loading = true;
  const searchModel = optimizeFields(props.search);
  const { data, total } = await props.request({
    page: state.pageNum,
    page_size: state.pageSize,
    ...props.initParams,
    ...searchModel
  });
  state.loading = false;
  state.tableData = data;
  state.total = total;
};

// Fetch data before component mounts
onBeforeMount(() => {
  getTableData();
});

const { searchModel, loading, tableData, total, pageNum, pageSize, paginationConfig, handleCurrentChange, handleSizeChange, handleSelectionChange, filterHandler } = toRefs(state);

defineExpose({
  getTableData
});
</script>

<style scoped name="wgTable">
::v-deep .el-select__wrapper {
  background-color: #222942;
  border-color: #222942;
  box-shadow: none;
}

::v-deep .el-select__placeholder {
  color: #5b7bab;
}

::v-deep .el-select {
  --el-border-radius-base: 0.5rem;
}

::v-deep .el-select__selection {
  padding: 0.5 1rem;
}

::v-deep .el-popper.is-light {
  background-color: #222942;
}

.table {
  padding: 20px;
  background: #191f33;
}

::v-deep .el-table tr {
  background: #191f33;
  color: #aac4ed;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 6px solid #0d101e;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 6px solid #0d101e;
}

::v-deep .el-table th.el-table__cell {
  background: #191f33;
  color: #80a4db;
}

::v-deep .el-table {
  --el-table-border: 6px solid #0d101e;
  --el-table-bg-color: #191f33;
  --el-table-border-color: #191f33;
  font-size: 0.875rem;
}

::v-deep .el-pagination {
  --el-pagination-bg-color: #0d101e;
  --el-pagination-text-color: #5b7bab;
  --el-pagination-button-disabled-color: #5b7bab;
  --el-pagination-border-radius: 0.375rem;
  --el-pagination-button-color: #5b7bab;
  --el-pagination-button-disabled-bg-color: none;
  --el-pagination-button-bg-color: #222942;
  --el-pagination-hover-color: #fff;
  color: #5b7bab;
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

::v-deep .el-pagination button {
  font-weight: 700;
  background: #222942 !important;
  color: #5b7bab !important;
}

::v-deep .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: #222942 !important;
}

::v-deep .el-pager li.is-active {
  color: #fff;
  background: #5b7bab !important;
}

::v-deep .el-loading-mask {
  background-color: #0d101e;
}

::v-deep .el-pagination__goto {
  margin-right: 0;
  width: 0;
  opacity: 0;
}

::v-deep .el-select__wrapper.is-hovering:not(.is-focused) {
  box-shadow: none;
}

::v-deep .el-pagination__sizes .el-pagination__sizes-label {
  display: none;
}

::v-deep .el-form--inline .el-form-item {
  margin-right: 0.5rem;
}

::v-deep .el-table {
  --el-table-row-hover-bg-color: transparent;
  /* background-color: transparent !important; */
}

.empty-img {
  fill: #5b7bab;
  width: 150px;
}
</style>
