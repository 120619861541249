<!-- eslint-disable vue/require-toggle-inside-transition -->
<script setup>
import mySearchGameList from '@/components/game/searchGameList.vue';
import smallGameImg1 from '../../assets/images/home/btn_top_game_2.png';
import smallGameImg2 from '../../assets/images/home/btn_top_game.png';
// import smallSportsImg1 from '../../assets/images/home/btn_top_spots_2.png';
// import smallSportsImg2 from '../../assets/images/home/btn_top_spots.png';
import bGameImg1 from '../../assets/images/home/btn_top_game_b_2.png';
import bGameImg2 from '../../assets/images/home/btn_top_game_b.png';
import bSportsImg1 from '../../assets/images/home/btn_top_spots_b_1.png';
import bSportsImg2 from '../../assets/images/home/btn_top_spots_b.png';
import menuHeader from '../../assets/images/home/btn_cd_ss_1.svg';
import homeImg from '../../assets/images/home/btn_cd_ss_1.svg';
import homeImg1 from '@/assets/images/new-version/menu/HomeFilled-2.svg';
import homeImg2 from '@/assets/images/new-version/menu/HomeFilled.svg';
import favoritesImg1 from '@/assets/images/new-version/menu/FavoriteFilled-2.svg';
import favoritesImg2 from '@/assets/images/new-version/menu/FavoriteFilled.svg';
import recentImg1 from '@/assets/images/new-version/menu/QueryBuilderFilled-2.svg';
import recentImg2 from '@/assets/images/new-version/menu/QueryBuilderFilled.svg';
import promotionImg1 from '@/assets/images/new-version/menu/liwu-2.svg';
import promotionImg2 from '@/assets/images/new-version/menu/liwu.svg';
import dailyContestImg1 from '@/assets/images/new-version/menu/daily_ico2.svg';
import dailyContestImg2 from '@/assets/images/new-version/menu/daily_ico1.svg';
import referralImg1 from '@/assets/images/new-version/menu/Referral-2.svg';
import referralImg2 from '@/assets/images/new-version/menu/Referral.svg';
import menuVip from '@/assets/images/new-version/menu/VIP.svg';
import menuVipSelected from '@/assets/images/new-version/menu/VIP-2.svg';
import menuAffiliate from '@/assets/images/new-version/menu/Affiliate.svg';
import menuAffiliateSelected from '@/assets/images/new-version/menu/Affiliate-2.svg';
import weeklyDrawSvg from '@/assets/images/new-version/menu/weekly_draw.svg';

import slotsImg1 from '@/assets/images/new-version/menu/Slots.svg';
import slotsImg2 from '@/assets/images/new-version/menu/Slots-2.svg';
import popularSlotsImg1 from '@/assets/images/new-version/menu/Wingame Popular-2.svg';
import popularSlotsImg2 from '@/assets/images/new-version/menu/Wingame Popular.svg';
import newSlotsImg1 from '@/assets/images/new-version/menu/New Release-2.svg';
import newSlotsImg2 from '@/assets/images/new-version/menu/New Release.svg';
import hotSlotsImg1 from '@/assets/images/new-version/menu/Hot Game-2.svg';
import hotSlotsImg2 from '@/assets/images/new-version/menu/Hot Game.svg';
import liveCasinoImg1 from '@/assets/images/new-version/menu/Live Casino-2.svg';
import liveCasinoImg2 from '@/assets/images/new-version/menu/Live Casino.svg';
import baccaratImg1 from '@/assets/images/new-version/menu/Baccarat.svg';
import baccaratImg2 from '@/assets/images/new-version/menu/Baccarat-2.svg';

import menuHelp from '@/assets/images/new-version/menu/Help-2.svg';
import menuHelp2 from '@/assets/images/new-version/menu/Help.svg';

import in_house_gameImg1 from '../../assets/images/home/btn_8.svg';
import in_house_gameImg2 from '../../assets/images/home/btn_8_2.svg';

import pokerImg1 from '../../assets/images/home/btn_9.svg';
import pokerImg2 from '../../assets/images/home/btn_9_2.svg';
import gameSupportImg from '@/assets/images/new-version/menu/Providers.svg';
import gameSupportImg2 from '@/assets/images/new-version/menu/Providers-2.svg';
// import {getCustomerLinkService} from "@/assets/network/service/home";

import { toast } from 'vue3-toastify';
import { ref, computed, } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAppStore, useLoginStore } from '@/store/store';
import liveSupportImg from '@/assets/images/new-version/menu/Live Support.svg';
import liveSupportImg2 from '@/assets/images/new-version/menu/Live Support-2.svg';
import { HideIntercom, ShowIntercom } from '@/assets/tools/CustomerType';
import emitter from '@/assets/tools/eventBus';
// import turntableDialog from '@/components/turntableDialog';
// import { getTurntableList } from '@/assets/network/service/turntable'

const { t } = useI18n();
const appStore = useAppStore();
let initShow = ref(true);
const isLogin = computed(() => appStore.isLogin);

const location = useRoute();
// const sportsShow = ref(false)
const gameCategory = ref('Game')
let menuStr = localStorage.getItem('showMenu');
if (menuStr) {
  initShow = ref(menuStr === 'true');
} else {
  if (window.innerWidth <= serverConfig.breakPx[0]) {
    initShow = ref(false);
  } else {
    initShow = ref(true);
  }
}

let show = ref(initShow);
const finish = ref(true);
const changeShow = function () {
  if (finish.value) {
    show.value = !show.value;
    finish.value = false;
    setTimeout(function () {
      finish.value = true;
    }, 400);
  }
};
const menus = ref([
  {
    name: t('home'),
    selectedImg: homeImg1,
    img: homeImg2,
    path: '/',
    code: 'home',
    auth: false
  },
  {
    name: t('favorites'),
    img: favoritesImg2,
    selectedImg: favoritesImg1,
    path: '/favorites',
    code: 'favorites',
    auth: true
  },
  {
    name: t('recent'),
    img: recentImg2,
    selectedImg: recentImg1,
    path: '/recent',
    code: 'recent',
    auth: true
  },
  {
    name: t('promotion'),
    img: promotionImg2,
    selectedImg: promotionImg1,
    path: '/promotion',
    code: 'promotion',
    auth: false
  },
  {
    name: t('daily_contest'),
    img: dailyContestImg2,
    selectedImg: dailyContestImg1,
    path: '/dailyContest',
    code: 'daily_contest',
    auth: false
  },
  {
    name: t('referral'),
    img: referralImg2,
    selectedImg: referralImg1,
    path: '/referral',
    code: 'referral',
    auth: false
  },
  {
    name: t('vip_club'),
    img: menuVip,
    selectedImg: menuVipSelected,
    path: '/profile/vip',
    code: 'vip_club',
    auth: false
  },
  {
    name: t('affiliate_program'),
    img: menuAffiliate,
    selectedImg: menuAffiliateSelected,
    path: '/affiliate-program',
    code: 'affiliate_program',
    auth: false
  },
  {
    name: t('weekly_draw_001'),
    img: weeklyDrawSvg,
    selectedImg: weeklyDrawSvg,
    path: '/weekly-draw',
    code: 'weekly-draw',
    auth: false
  }
]);
const gameMenus = ref([
  {
    name: ref(t('slots')),
    selectedImg: slotsImg2,
    img: slotsImg1,
    path: '/slots/allSlots',
    code: 'slots'
  },
  {
    name: ref(t('popular_slots')),
    selectedImg: popularSlotsImg1,
    img: popularSlotsImg2,
    path: '/slots/popularSlots',
    code: 'win_game_popular'
  },
  {
    name: t('hot_slots'),
    selectedImg: hotSlotsImg1,
    img: hotSlotsImg2,
    path: '/slots/hotSlots',
    code: 'hot_game'
  },
  {
    isHidden: true,
    name: t('in_house_game'),
    selectedImg: in_house_gameImg1,
    img: in_house_gameImg2,
    path: '',
    code: 'in_house_game',
    show: false,
    childRen: [
      {
        img: homeImg,
        path: '/'
      },
      {
        img: homeImg,
        path: '/'
      },
      {
        img: homeImg,
        path: '/'
      },
      {
        img: homeImg,
        path: '/'
      },
      {
        img: homeImg,
        path: '/'
      },
      {
        img: homeImg,
        path: '/'
      },
      {
        img: homeImg,
        path: '/'
      },
      {
        img: homeImg,
        path: '/'
      }
    ]
  },
  {
    isHidden: true,
    name: t('poker'),
    selectedImg: pokerImg1,
    img: pokerImg2,
    path: '/poker',
    code: 'poker'
  },
  {
    name: t('live_casino'),
    selectedImg: liveCasinoImg1,
    img: liveCasinoImg2,
    path: '/liveCasino',
    code: 'live_casino'
  },
  {
    name: t('baccarat'),
    selectedImg: baccaratImg2,
    img: baccaratImg1,
    path: '/baccarat',
    code: 'baccarat'
  },
  {
    name: t('new_slots'),
    selectedImg: newSlotsImg1,
    img: newSlotsImg2,
    path: '/slots/newSlots',
    code: 'new_release'
  }
]);

// 转盘活动列表
// const activeList = ref([])
// 是否主动触发转盘活动
// const activeInitiativeTab = ref(false)
// const helpMenus = ref([
//   {
//     isHidden: false,
//     name: t('help_center'),
//     selectedImg: menuHelp,
//     img: menuHelp2,
//     path: '/helpCenter',
//     code: 'help_center'
//   }
// ]);


const isSelected = (router, path) => {
  return router.currentRoute.value.fullPath == path;
};


const menuClick = (router, menu) => {
  const token = getCookieAccessToken();
  // 此時代表未登录
  if (menu.auth && !token) {
    return;
  }
  if (menu.show) {
    menu.show = false;
  } else {
    menu.show = true;
  }

  // menu.icon = menu.img
  if (location.path == menu.path) {
    window.location.reload();
  } else if (menu.path) {
    window.sessionStorage.setItem('sortBy', JSON.stringify([]));
    router.push({ path: menu.path });
  } else if (menu.code == 'live_support') {
    // openNewWindow(customer_service_link.value)

    if (!appStore.openCustomer) {
      if (appStore.customerShowType === 'Intercom') {
        ShowIntercom();
        const params = {
          kwai_pixel_id: appStore.kwaiPixelId,
          kwai_token: appStore.kwaiToken,
          click_id: appStore.kwaiClickId,
          event_name: 'contact'
        }
        kwaiEventService(params);
      }
      appStore.openCustomerPopup();
    } else {
      if (appStore.customerShowType === 'Intercom') {
        HideIntercom();
      }
      appStore.closeCustomerPopup();
    }
  }
};

const gameCategoryChange = async (gameCategory1) => {
  if (gameCategory1 == 'Sports') {
    // const res = await getTurntableList()
    // if (res.data.data.records.length === 0) return;
    // activeList.value = res.data.data.records;
    // activeInitiativeTab.value = false
    // appStore.sportsShow = true
    emitter.emit('custom-showSpin')
    return;
  }
  gameCategory.value = gameCategory;
}
//  async function openNewWindow(url){
//     const newWindow = window.open(url, '_blank');
//     newWindow.focus();
//   }

// const providerMenus = ref([
//   {
//     name: t('provider'),
//     selectedImg: gameSupportImg2,
//     img: gameSupportImg,
//     path: '/providers',
//     code: 'providers',
//     auth: false
//   }
// ]);

// const liveSupportMenus = ref([
//   {
//     selectedImg: liveSupportImg2,
//     img: liveSupportImg,
//     code: 'live_support',
//     auth: false
//   }
// ]);

const moreMenus = ref([
  {
    isHidden: false,
    name: t('help_center'),
    selectedImg: menuHelp,
    img: menuHelp2,
    path: '/helpCenter',
    code: 'help_center'
  },
  {
    name: t('providers'),
    selectedImg: gameSupportImg2,
    img: gameSupportImg,
    path: '/providers',
    code: 'providers',
    auth: false
  },
  {
    selectedImg: liveSupportImg2,
    img: liveSupportImg,
    code: 'live_support',
    auth: false
  }
]);

// watch(
//   () => location.fullPath,
//   async (newPath) => {
//     if (newPath === '/') {
//       const res = await getTurntableList()
//       if (res.data.data.records.length === 0) return;
//       const isHomeTab = res.data.data.records.some(item => item.home_tab === true);
//       if (!isHomeTab) return;
//       activeInitiativeTab.value = true
//       activeList.value = res.data.data.records;
//       appStore.sportsShow = true
//     }
//   },
//   {
//     immediate: true
//   }
// );

// const showSpinWheelAfterLogin = async () => {
//   const res = await getTurntableList()
//   if (res.data.data.records.length === 0) return;
//   const isHomeTab = res.data.data.records.some(item => item.home_tab === true);
//   if (!isHomeTab) return;
//   activeInitiativeTab.value = true
//   activeList.value = res.data.data.records;
//   appStore.sportsShow = true
// }
//   const customer_service_link = ref('')
// getCustomerLinkService().then(response=>{
//     // window.open(response.data.customer_service_link, '_blank');
//     customer_service_link.value = response.data.customer_service_link
// }).catch(error=>{
//     const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
//     let errorMsg = msg.length > 0 ? msg[0] : error.message;
//     if(error.response.status === 500) {
//       errorMsg = error.message;
//     }
//     toast(errorMsg,{autoClose:2000,type:'error'})
// })

// // 定义事件处理函数
// const handleCustomShowSpin = (_type) => {
//   showSpinWheelAfterLogin();
// };

// onMounted(() => {
//   emitter.on('custom-showSpin',handleCustomShowSpin)
// })
// onUnmounted(() => {
//   emitter.off('custom-showSpin',handleCustomShowSpin);
// })

</script>
<script>
import myI18n from '@/components/myI18n.vue';
import { getCookieAccessToken } from '@/assets/tools/CommonTool';
import serverConfig from '@/assets/network/serverConfig';

import { openboxSearchGames } from '@/assets/network/service/game';
import { kwaiEventService } from '@/assets/network/service/user';

export default {
  name: 'myMenus',
  components: {
    myI18n
  },
  watch: {
    phone_show_menu: function (newVal) {
      let parentElement = document.getElementsByClassName('root_div');
      if (newVal) {
        if (parentElement && parentElement.length > 0) {
          let allChildren = parentElement[0].querySelectorAll('.main-content');
          allChildren.forEach((item) => item.classList.add('phone_hidden'));
        }
      } else {
        if (parentElement && parentElement.length > 0) {
          let allChildren = parentElement[0].querySelectorAll('.main-content');
          allChildren.forEach((item) => item.classList.remove('phone_hidden'));
        }
      }
    },
    showMenu: function () {
      const that = this;
      let menuRoot = document.getElementById('menu_root');
      if (this.showMenu && menuRoot) {
        menuRoot.classList.remove('menu2');
      } else if (menuRoot) {
        menuRoot.classList.add('menu2');
      }
      let header_root = document.getElementById('header_root');
      if (!header_root) {
        return;
      }
      if (this.showMenu) {
        header_root.classList.remove('header2');
      } else {
        header_root.classList.add('header2');
      }
      let style2 = that.showMenu ? '' : 'display:none !important;';

      if (!that.showMenu) {
        let all_menu = document.getElementById('all_menu');
        all_menu.style = style2;
      }
      setTimeout(function () {
        style2 = that.showMenu ? '' : 'display:none !important;';
        let all_menu = document.getElementById('all_menu');
        if (all_menu) {
          all_menu.style = style2 + (that.showMenu ? 'height: calc(100vh - 4rem);' : 'height:0;');
        }
        style2 = !that.showMenu ? '' : 'display:none !important;';
        let all_menu2 = document.getElementById('all_menu2');
        if (all_menu2) {
          all_menu2.style = style2 + (!that.showMenu ? '    height: calc(100vh - 4rem);' : 'height:0;');
        }
      }, 400);
      setTimeout(this.setDom, 0);
      // this.setDom()
    }
  },
  data() {
    return {
      searchText: '',
      isGameSearch: false,
      searchResult: [],
      searchParams: {
        game_type: '',
        name: '',
        start: 0,
        end: 20,
        page_name: '',
        game_provider: [],
        game_sub_type: ''
      },
      phone_show_menu: false,
      footerMenus: [
        {
          menuCode: 'home',
          selectedImg: homeImg1,
          img: homeImg2,
          path: '/'
        },
        {
          menuCode: 'game',
          selectedImg: slotsImg2,
          img: slotsImg1,
          path: '/slots/allSlots'
        },
        {
          menuCode: 'favorites',
          selectedImg: favoritesImg1,
          img: favoritesImg2,
          path: '/favorites'
        },
        {
          menuCode: 'recent',
          selectedImg: recentImg1,
          img: recentImg2,
          path: '/recent'
        },
        {
          menuCode: 'referral',
          selectedImg: referralImg1,
          img: referralImg2,
          path: '/referral'
        }
      ],
      showMenu: false,
      style: '',
      showMenuFinish: true,
    };
  },
  created() {
    window.addEventListener('resize', this.resize);
    // this.setDom()
  },
  mounted() {
    let menuStr = localStorage.getItem('showMenu');
    if (menuStr) {
      this.showMenu = menuStr === 'true';
    } else {
      if (window.innerWidth <= serverConfig.breakPx[0]) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    }
    document.getElementsByTagName('body')[0].classList.remove('hidden-overflow-y');
    let show = this.showMenu;
    let dom = document.getElementById('menu_root');
    if (show && dom) {
      dom.classList.remove('menu2');
    } else if (dom) {
      dom.classList.add('menu2');
    }
    let right_main = document.getElementById('right_main');
    if (show && right_main) {
      right_main.classList.remove('right_main2');
    } else if (right_main) {
      right_main.classList.add('right_main2');
    }
    let header_root = document.getElementById('header_root');
    if (show && header_root) {
      header_root.classList.remove('header2');
    } else if (header_root) {
      header_root.classList.add('header2');
    }

    let topClass = this.$isMobile() ? 'phone' : 'web';
    if (topClass == 'phone') {
      console.log(topClass);
    } else if (window.innerWidth <= serverConfig.breakPx[1]) {
      topClass = 'phone';
    } else if (window.innerWidth <= serverConfig.breakPx[0]) {
      topClass = 'web web-960';
    } else {
      topClass = 'web';
    }
    this.topClass = topClass;
    let style2 = this.showMenu ? '' : 'display:none !important;';

    let all_menu = document.getElementById('all_menu');
    if (all_menu) {
      all_menu.style = style2;
    }
    style2 = !this.showMenu ? '' : 'display:none !important;';
    let all_menu2 = document.getElementById('all_menu2');
    if (all_menu2) {
      all_menu2.style = style2;
    }
    setTimeout(this.resize, 0);
    setTimeout(this.resize, 300);
    setTimeout(this.resize, 2000);
  },
  methods: {
    enterSearch() {
      console.log(this.searchText);
      if (!this.searchText && this.searchText.trim().length <= 3) {
        this.searchText = this.searchText.trim();
        return;
      }
      this.searchResult = [];
      this.searchParams.name = this.searchText;
      this.getSlotsListService();
    },
    getSlotsListService() {
      const that = this;
      this.searchParams.start = 1;
      this.searchParams.end = this.searchParams.start + 20;
      openboxSearchGames(that.searchParams)
        .then((response) => {
          this.searchResult = response.data.data;
          this.provider_counts = response.data.provider_counts;
          this.total = response.data.total_count;
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    jump(footMenu) {
      if (footMenu.path == '/') {
        // 狀態廣利
        this.phone_show_menu = !this.phone_show_menu;
      } else {
        if (this.$route.path == footMenu.path) {
          window.location.reload();
          return;
        }
        if (footMenu.menuCode == 'favorites' || footMenu.menuCode == 'recent') {
          const appStore = useAppStore();
          if (!appStore.isLogin) {
            const loginStore = useLoginStore();
            loginStore.updateShow(true);
            loginStore.updateActType('login');
            return;
          }
        }
        window.sessionStorage.setItem('sortBy', JSON.stringify([]));
        this.$router.push(footMenu.path);
      }
    },
    setDom() {
      let style = '';
      let menuRoot = document.getElementById('menu_root');
      if (!menuRoot) {
        return;
      }
      let sub308 = '76px';
      if (this.topClass == 'phone') {
        sub308 = '0';
        style = 'width:calc(100% - ' + sub308 + ');';
      } else if (this.topClass == 'web web-960') {
        sub308 = '56px';
        style = 'width:calc(100% - ' + sub308 + ');';
      } else {
        if (this.showMenu) {
          style = 'width:calc(100% - var(--web-menu-width));';
        } else {
          style = 'width:calc(100% - var(--web-menu2-width));';
        }
      }

      let dom = document.getElementById('header_root');
      if (dom) {
        dom.style = style;
        dom.style = '';
      } else {
        this.$nextTick(() => {
          if (this.topClass !== 'phone') {
            this.setDom();
          }
        });
      }

      console.log(window.innerWidth);
      let menuStr = localStorage.getItem('showMenu');
      let showMenu = false;
      if (menuStr) {
        showMenu = menuStr === 'true';
      } else {
        showMenu = true;
      }

      sub308 = 308;
      // if (this.topClass == 'phone') {
      // this.changeLoginPageDiv()
      // } else
      if (window.innerWidth <= 780) {
        this.topClass = 'phone';
      } else if (window.innerWidth <= serverConfig.breakPx[0]) {
        this.topClass = 'web web-960';
        sub308 = 140;
      }
      if (!showMenu) {
        sub308 = 140;
      }
      // this.changeLoginPageDiv()
      if (this.topClass == 'phone') {
        let divs2 = document.getElementsByClassName('body');
        if (divs2 && divs2.length > 0) {
          for (let i = 0; i < divs2.length; i++) {
            divs2[i].style = '';
          }
        }
        let divs3 = document.getElementsByClassName('body2');
        if (divs3 && divs3.length > 0) {
          for (let i = 0; i < divs3.length; i++) {
            divs3[i].style = '';
          }
        }
        return;
      }

      let divs2 = document.getElementsByClassName('body');
      let bodyStyle = '';
      let max_width = window.innerWidth - sub308;
      if (max_width > serverConfig.breakPx[0]) {
        max_width = serverConfig.breakPx[0];
      }
      if (max_width < serverConfig.breakPx[1]) {
        max_width = serverConfig.breakPx[1] - 64;
      }
      bodyStyle = 'max-width:' + max_width + 'px;width:' + max_width + 'px;';
      if (divs2 && divs2.length > 0) {
        for (let i = 0; i < divs2.length; i++) {
          divs2[i].style = bodyStyle;
        }
      }
      let divs3 = document.getElementsByClassName('body2');
      let body2Style = '';
      max_width = window.innerWidth - sub308;
      if (max_width > serverConfig.breakPx[0]) {
        max_width = serverConfig.breakPx[0];
      }
      if (max_width < serverConfig.breakPx[1]) {
        max_width = serverConfig.breakPx[1] - 64;
      }
      body2Style = 'max-width:' + max_width + 'px;width:' + max_width + 'px';
      if (divs3 && divs3.length > 0) {
        for (let i = 0; i < divs3.length; i++) {
          divs3[i].style = body2Style;
        }
      }
      console.log('-----------------this.$route.path----------------');
      console.log(this.$route.path);
      if (this.$route.path == '/') {
        this.$emit('menuChange');
      }
    },
    resize() {
      const that = this;
      that.topClass = that.$isMobile() ? 'phone' : 'web';
      if (window.innerWidth <= 780) {
        that.topClass = 'phone';
      } else if (window.innerWidth <= serverConfig.breakPx[0]) {
        that.topClass = 'web web-960';
      }
      console.log(window.innerWidth);
      this.$nextTick(() => {
        if (this.topClass !== 'phone') {
          this.setDom();
        }
      });
      if (window.innerWidth >= serverConfig.breakPx[0]) {
        return;
      }
      if (window.innerWidth <= serverConfig.breakPx[0] && window.innerWidth >= serverConfig.breakPx[1]) {
        // this.showMenu = false
        // localStorage.setItem('showMenu',this.showMenu)
        return;
      }
      if (window.innerWidth <= serverConfig.breakPx[1]) {
        //this.showMenu = false
        //localStorage.setItem('showMenu',this.showMenu)
        return;
      }
    },
    showMenuChange() {
      if (this.showMenuFinish) {
        this.showMenuFinish = false;
        this.showMenu = !this.showMenu;
        let show = this.showMenu;
        let dom = document.getElementById('menu_root');
        let right_main = document.getElementById('right_main');
        const that = this;
        if (show) {
          dom.classList.remove('menu2');
          right_main && right_main.classList.remove('right_main2');
          // dom.classList.remove('menu3')
          that.setDom();
        } else {
          that.$nextTick(() => {
            dom.classList.add('menu2');
            right_main && right_main.classList.add('right_main2');
            that.setDom();
          });
        }
        // let showMenuStr = localStorage.getItem('showMenu')
        localStorage.setItem('showMenu', this.showMenu);
        console.log(localStorage.getItem('showMenu'));

        setTimeout(function () {
          that.showMenuFinish = true;
        }, 400);
      }
    }
  }
};
</script>
<template>
  <div @click="
    changeShow();
  showMenuChange();
  " class="wrap svelte-sizi7f">
    <div data-layout="" style="width: 60px"></div>
    <div data-layout="" v-show="showMenu" class="overlay svelte-203g65"
      style="--sidebar-z-index: 899; --header-height: 60px"></div>
  </div>
  <div v-show="phone_show_menu && topClass == 'phone'">
    <div data-layout="" class="overlay svelte-203g64" style="--sidebar-z-index: 1; --header-height: 60px"></div>
  </div>

  <div :class="'menu scrollbar-width-thin ' + (topClass == 'phone' && phone_show_menu ? 'show' : '')"
    v-show="topClass == 'phone' && phone_show_menu">
    <div :class="'menu' + ' menu-header'">
      <div class="img" style="float: left">
        <img :style="gameCategory == 'Game' ? '' : 'display:none;'" class="img" :src="smallGameImg1"
          @click="gameCategoryChange('Game')" />
        <img :style="gameCategory !== 'Game' ? '' : 'display:none;'" class="img" :src="smallGameImg2"
          @click="gameCategoryChange('Game')" />
        <div class="menu-header-name" @click="gameCategoryChange('Game')">{{ t('game') }}</div>
      </div>
      <div class="w-[45%] rounded-lg float-left p-[0.375rem] h-[2.4rem] flex items-center ml-2 gap-1 justify-center"
        style="background: linear-gradient(263deg, #35164E 7.97%, #4E1A60 49.06%, #7D227E 90.14%);"
        @click="gameCategoryChange('Sports')">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12.0121 0.0562744C5.42373 0.0562744 0.0838013 5.40999 0.0838013 12.0149C0.0838013 18.6198 5.42373 23.9721 12.0107 23.9721C18.5977 23.9721 23.9403 18.6184 23.9403 12.0149C23.9403 5.41137 18.599 0.0562744 12.0121 0.0562744Z"
            fill="#FECC86" />
          <path
            d="M19.3907 19.4106L14.7487 14.7535C14.2687 15.2335 13.6714 15.5798 13.0161 15.7577L14.717 22.1212C13.8355 22.3571 12.9278 22.4771 12.0146 22.4757C11.0918 22.4757 10.1882 22.3557 9.31225 22.1212L11.0131 15.7577C10.3565 15.5825 9.7592 15.2349 9.28052 14.7535L4.63309 19.4106C3.6785 18.4573 2.91841 17.3276 2.39559 16.0847C2.20937 15.6418 2.05211 15.1852 1.92657 14.7217L8.27213 13.0167C8.09694 12.3587 8.09694 11.6662 8.27213 11.0082L1.92657 9.30319C2.40249 7.53057 3.33501 5.91383 4.63171 4.61437L9.27363 9.2687C9.75368 8.78727 10.351 8.44102 11.0062 8.26445L9.30811 1.90372C10.1896 1.66783 11.0987 1.54919 12.0105 1.54919C12.9334 1.54919 13.8369 1.66921 14.7129 1.90234L13.012 8.26307C13.6479 8.43274 14.2466 8.76796 14.7446 9.26732L19.3907 4.61162C20.3439 5.56483 21.104 6.69323 21.6268 7.93614C21.8158 8.38308 21.9703 8.83831 22.0958 9.29905L15.7475 11.0041C15.9227 11.6621 15.9227 12.3546 15.7475 13.0126L22.0958 14.7176C21.9716 15.1825 21.8158 15.6377 21.6268 16.0805C21.1053 17.3248 20.3452 18.456 19.3893 19.4092L19.3907 19.4106Z"
            fill="white" />
          <mask id="mask0_1373_9684" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
            height="24">
            <path d="M23.971 0H0V24H23.971V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1373_9684)">
            <path
              d="M12.6408 8.79062L12.0117 5.71716L11.4282 8.78234C10.7716 8.8996 10.166 9.21688 9.69559 9.69003C8.41682 10.9729 8.41682 13.049 9.69559 14.3319C10.3095 14.9472 11.1426 15.2934 12.0103 15.2934C12.878 15.2934 13.7126 14.9472 14.3251 14.3319C15.6039 13.049 15.6039 10.9729 14.3251 9.69003C13.8643 9.23067 13.2781 8.91753 12.6394 8.792L12.6408 8.79062Z"
              fill="#FF7F00" />
            <path
              d="M11.9375 0.0265503C5.34369 0.0265503 -0.0045166 5.38579 -0.0045166 11.999C-0.0045166 18.6121 5.34231 23.9714 11.9375 23.9714C18.5328 23.9714 23.881 18.6108 23.881 11.999C23.881 5.38717 18.5355 0.0265503 11.9389 0.0265503H11.9375ZM11.9375 22.0967C6.37691 22.0967 1.86604 17.5775 1.86604 11.999C1.86604 6.42039 6.37691 1.89987 11.9389 1.89987C17.5009 1.89987 22.0159 6.41901 22.0159 11.9976C22.0159 17.5762 17.5051 22.0953 11.9389 22.0953H11.9375V22.0967Z"
              fill="#FF7F00" />
          </g>
        </svg>
        <div class="text-base ml-[0.5rem]">{{ t('scheduleInfo_021') }}</div>
      </div>
    </div>

    <div style="clear: both; margin-bottom: 0.6rem"></div>
    <transition name="slide-fade">
      <div style="width: calc(100% - 16px); margin-top: 8.75rem">
        <div class="menu tool-bar-search phone" style="">
          <div v-if="isGameSearch" class="phone search-overlay svelte-o7w8d3" style="" @click="isGameSearch = false">
          </div>
          <div :class="'tool-bar-search bottom-1rem phone search-div  ' + (isGameSearch ? 'searching' : '')">
            <div :class="'input-zindex1550 line-height-2_5rem ' + (isGameSearch ? 'focus' : '')">
              <div :class="'line-height-2_5rem input-div'" @click.stop="isGameSearch = true">
                <div class="img-div line-height-2_5rem">
                  <img style="width: 1.25rem; height: 1.25rem" src="../../assets/images/home/Frame 427320295.svg" />
                </div>
                <div class="input line-height-2_5rem">
                  <input v-model="searchText" type="input" @keyup.enter="enterSearch"
                    :placeholder="t('input_search')" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="" style="height: 0">
          <div class="search-history" style="" v-if="isGameSearch" @click.stop="isGameSearch = true">
            <div v-if="searchResult.length > 0">
              <my-search-game-list :game-list="searchResult"></my-search-game-list>
            </div>
            <div v-if="searchText.length < 3" style="width: 100%; text-align: center" class="search-remind">
              <div style="color: var(--color-8ea3c3)">{{ t('search_text') }}</div>
            </div>
          </div>
        </div>
        <div :class="'menu-body scrollbar'">
          <div :class="'menu'">
            <div
              :class="'menu-item ' + (menu.auth && !isLogin ? 'not-allowed ' : '') + (isSelected($router, menu.path) ? 'menu-selected' : '')"
              v-for="(menu, index) in menus" :key="index" @click="menuClick($router, menu)"
              @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)"
                  :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)"
                  :src="menu.selectedImg" />
              </div>
              <div class="menu-item-text" v-if="menu.code">
                {{ t(menu.code) }}
              </div>
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
            </div>

            <div style="clear: both"></div>
          </div>
          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <div :class="'menu-item title'">
              <div class="menu-item-text">{{ t('game') }}</div>
            </div>
            <div :class="'line'"></div>
            <div @click="menuClick($router, menu)"
              :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))"
              v-for="(menu, index) in gameMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true"
              @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)"
                  :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)"
                  :src="menu.selectedImg" />
              </div>
              <div class="menu-item-text" v-if="menu.code">
                {{ t(menu.code) }}
              </div>
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
              <div v-if="menu.childRen" v-show="menu.show" class="menu-item-children" style="clear: both">
                <div v-for="(cMenu, cIndex) in menu.childRen" @click="menuClick($router, cMenu.path)"
                  :key="'index' + cIndex" style="float: left; margin: 0.2rem">
                  <div class="children-menu">
                    <img :src="cMenu.img" />
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <!-- 新UI-移动端菜单-开始 -->
          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <div :class="'menu-item title'">
              <div class="menu-item-text">{{ t('more') }}</div>
            </div>
            <div :class="'line'"></div>
            <div @click="menuClick($router, menu)"
              :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))"
              v-for="(menu, index) in moreMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true"
              @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)"
                  :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)"
                  :src="menu.selectedImg" />
              </div>
              <div class="menu-item-text" v-if="menu.code">
                {{ t(menu.code) }}
              </div>
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
              <div v-if="menu.childRen" v-show="menu.show" class="menu-item-children" style="clear: both">
                <div v-for="(cMenu, cIndex) in menu.childRen" @click="menuClick($router, cMenu.path)"
                  :key="'index' + cIndex" style="float: left; margin: 0.2rem">
                  <div class="children-menu">
                    <img :src="cMenu.img" />
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <!-- 新UI-移动端菜单-结束 -->
          <!-- <div style="clear: both; margin-bottom: 0.6rem"></div>
           <div :class="'menu'">
            <div @click="menuClick($router, menu)" :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))" v-for="(menu, index) in helpMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)" :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)" :src="menu.selectedImg" />
              </div>
              <div class="menu-item-text" v-if="menu.code">
                {{ t(menu.code) }}
              </div>
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
              <div v-if="menu.childRen" v-show="menu.show" class="menu-item-children" style="clear: both">
                <div v-for="(cMenu, cIndex) in menu.childRen" @click="menuClick($router, cMenu.path)" :key="'index' + cIndex" style="float: left; margin: 0.2rem">
                  <div class="children-menu">
                    <img :src="cMenu.img" />
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <div @click="menuClick($router, menu)" :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))" v-for="(menu, index) in providerMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)" :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)" :src="menu.selectedImg" />
              </div>
              <div class="menu-item-text" v-if="menu.code">
                {{ t(menu.code) }}
              </div>
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
              <div v-if="menu.childRen" v-show="menu.show" class="menu-item-children" style="clear: both">
                <div v-for="(cMenu, cIndex) in menu.childRen" @click="menuClick($router, cMenu.path)" :key="'index' + cIndex" style="float: left; margin: 0.2rem">
                  <div class="children-menu">
                    <img :src="cMenu.img" />
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <div @click="menuClick($router, menu)" :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))" v-for="(menu, index) in liveSupportMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)" :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)" :src="menu.selectedImg" />
              </div>
              <div class="menu-item-text" v-if="menu.code">
                {{ t(menu.code) }}
              </div>
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
              <div v-if="menu.childRen" v-show="menu.show" class="menu-item-children" style="clear: both">
                <div v-for="(cMenu, cIndex) in menu.childRen" @click="menuClick($router, cMenu.path)" :key="'index' + cIndex" style="float: left; margin: 0.2rem">
                  <div class="children-menu">
                    <img :src="cMenu.img" />
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div> -->

          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <my-i18n :showMenu="true"></my-i18n>
            <div style="clear: both"></div>
          </div>
          <div style="clear: both; margin-bottom: 5rem"></div>
        </div>
      </div>
    </transition>
  </div>
  <div :class="'menu scrollbar-width-thin '" id="menu_root">
    <div :class="'menu' + ' menu-header'" v-show="show">
      <img :src="menuHeader" @click.stop="
        changeShow();
      showMenuChange();
      " style="float: left" />
      <div class="img" style="float: left">
        <img :style="gameCategory == 'Game' ? '' : 'display:none;'" class="img" :src="smallGameImg1"
          @click="gameCategoryChange('Game')" />
        <img :style="gameCategory !== 'Game' ? '' : 'display:none;'" class="img" :src="smallGameImg2"
          @click="gameCategoryChange('Game')" />
        <div class="menu-header-name" @click="gameCategoryChange('Game')">{{ t('game') }}</div>
      </div>
      <div class="mt-[-2px] h-[1.8rem] rounded-lg float-left p-[0.375rem] w-[4rem] flex items-center ml-2 gap-1"
        style="background: linear-gradient(263deg, #35164E 7.97%, #4E1A60 49.06%, #7D227E 90.14%);"
        @click="gameCategoryChange('Sports')">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12.0121 0.0562744C5.42373 0.0562744 0.0838013 5.40999 0.0838013 12.0149C0.0838013 18.6198 5.42373 23.9721 12.0107 23.9721C18.5977 23.9721 23.9403 18.6184 23.9403 12.0149C23.9403 5.41137 18.599 0.0562744 12.0121 0.0562744Z"
            fill="#FECC86" />
          <path
            d="M19.3907 19.4106L14.7487 14.7535C14.2687 15.2335 13.6714 15.5798 13.0161 15.7577L14.717 22.1212C13.8355 22.3571 12.9278 22.4771 12.0146 22.4757C11.0918 22.4757 10.1882 22.3557 9.31225 22.1212L11.0131 15.7577C10.3565 15.5825 9.7592 15.2349 9.28052 14.7535L4.63309 19.4106C3.6785 18.4573 2.91841 17.3276 2.39559 16.0847C2.20937 15.6418 2.05211 15.1852 1.92657 14.7217L8.27213 13.0167C8.09694 12.3587 8.09694 11.6662 8.27213 11.0082L1.92657 9.30319C2.40249 7.53057 3.33501 5.91383 4.63171 4.61437L9.27363 9.2687C9.75368 8.78727 10.351 8.44102 11.0062 8.26445L9.30811 1.90372C10.1896 1.66783 11.0987 1.54919 12.0105 1.54919C12.9334 1.54919 13.8369 1.66921 14.7129 1.90234L13.012 8.26307C13.6479 8.43274 14.2466 8.76796 14.7446 9.26732L19.3907 4.61162C20.3439 5.56483 21.104 6.69323 21.6268 7.93614C21.8158 8.38308 21.9703 8.83831 22.0958 9.29905L15.7475 11.0041C15.9227 11.6621 15.9227 12.3546 15.7475 13.0126L22.0958 14.7176C21.9716 15.1825 21.8158 15.6377 21.6268 16.0805C21.1053 17.3248 20.3452 18.456 19.3893 19.4092L19.3907 19.4106Z"
            fill="white" />
          <mask id="mask0_1373_9684" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
            height="24">
            <path d="M23.971 0H0V24H23.971V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1373_9684)">
            <path
              d="M12.6408 8.79062L12.0117 5.71716L11.4282 8.78234C10.7716 8.8996 10.166 9.21688 9.69559 9.69003C8.41682 10.9729 8.41682 13.049 9.69559 14.3319C10.3095 14.9472 11.1426 15.2934 12.0103 15.2934C12.878 15.2934 13.7126 14.9472 14.3251 14.3319C15.6039 13.049 15.6039 10.9729 14.3251 9.69003C13.8643 9.23067 13.2781 8.91753 12.6394 8.792L12.6408 8.79062Z"
              fill="#FF7F00" />
            <path
              d="M11.9375 0.0265503C5.34369 0.0265503 -0.0045166 5.38579 -0.0045166 11.999C-0.0045166 18.6121 5.34231 23.9714 11.9375 23.9714C18.5328 23.9714 23.881 18.6108 23.881 11.999C23.881 5.38717 18.5355 0.0265503 11.9389 0.0265503H11.9375ZM11.9375 22.0967C6.37691 22.0967 1.86604 17.5775 1.86604 11.999C1.86604 6.42039 6.37691 1.89987 11.9389 1.89987C17.5009 1.89987 22.0159 6.41901 22.0159 11.9976C22.0159 17.5762 17.5051 22.0953 11.9389 22.0953H11.9375V22.0967Z"
              fill="#FF7F00" />
          </g>
        </svg>
        <div>{{ t('scheduleInfo_021') }}</div>
      </div>
    </div>

    <div class="menu menu2 menu-header" v-show="!show">
      <img :src="menuHeader" @click.stop="
        changeShow();
      showMenuChange();
      " style="float: left" />
    </div>
    <transition name="slide-fade">
      <div style="width: 100%">
        <div :class="'menu-body scrollbar'" id="all_menu">
          <div :class="'menu'">
            <div
              :class="'menu-item ' + (menu.auth && !isLogin ? 'not-allowed ' : '') + (isSelected($router, menu.path) ? 'menu-selected' : '')"
              v-for="(menu, index) in menus" :key="index" @click="menuClick($router, menu)"
              @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)"
                  :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)"
                  :src="menu.selectedImg" />
              </div>
              <div class="menu-item-text" v-if="menu.code">
                {{ t(menu.code) }}
              </div>
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
            </div>

            <div style="clear: both"></div>
          </div>
          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <div :class="'menu-item title'" style="color: #5b7bab">
              <div class="menu-item-text">{{ t('game') }}</div>
            </div>
            <!-- <div :class="'line'"></div> -->
            <div @click="menuClick($router, menu)"
              :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))"
              v-for="(menu, index) in gameMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true"
              @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)"
                  :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)"
                  :src="menu.selectedImg" />
              </div>
              <div class="menu-item-text" v-if="menu.code">
                {{ t(menu.code) }}
              </div>
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
              <div v-if="menu.childRen" v-show="menu.show" class="menu-item-children" style="clear: both">
                <div v-for="(cMenu, cIndex) in menu.childRen" @click="menuClick($router, cMenu.path)"
                  :key="'index' + cIndex" style="float: left; margin: 0.2rem">
                  <div class="children-menu">
                    <img :src="cMenu.img" />
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <!-- 新UI-移动端菜单-开始2 -->
          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <div :class="'menu-item title'" style="color: #5b7bab">
              <div class="menu-item-text">{{ t('more') }}</div>
            </div>
            <!-- <div :class="'line'"></div> -->
            <div @click="menuClick($router, menu)"
              :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))"
              v-for="(menu, index) in moreMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true"
              @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)"
                  :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)"
                  :src="menu.selectedImg" />
              </div>
              <div class="menu-item-text" v-if="menu.code">
                {{ t(menu.code) }}
              </div>
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
              <div v-if="menu.childRen" v-show="menu.show" class="menu-item-children" style="clear: both">
                <div v-for="(cMenu, cIndex) in menu.childRen" @click="menuClick($router, cMenu.path)"
                  :key="'index' + cIndex" style="float: left; margin: 0.2rem">
                  <div class="children-menu">
                    <img :src="cMenu.img" />
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <!-- 新UI-移动端菜单-结束 -->
          <!-- <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <div @click="menuClick($router, menu)" :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))" v-for="(menu, index) in helpMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)" :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)" :src="menu.selectedImg" />
              </div>
              <div class="menu-item-text" v-if="menu.code">
                {{ t(menu.code) }}
              </div>
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
              <div v-if="menu.childRen" v-show="menu.show" class="menu-item-children" style="clear: both">
                <div v-for="(cMenu, cIndex) in menu.childRen" @click="menuClick($router, cMenu.path)" :key="'index' + cIndex" style="float: left; margin: 0.2rem">
                  <div class="children-menu">
                    <img :src="cMenu.img" />
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>

          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <div @click="menuClick($router, menu)" :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))" v-for="(menu, index) in providerMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)" :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)" :src="menu.selectedImg" />
              </div>
              <div class="menu-item-text" v-if="menu.code">
                {{ t(menu.code) }}
              </div>
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
              <div v-if="menu.childRen" v-show="menu.show" class="menu-item-children" style="clear: both">
                <div v-for="(cMenu, cIndex) in menu.childRen" @click="menuClick($router, cMenu.path)" :key="'index' + cIndex" style="float: left; margin: 0.2rem">
                  <div class="children-menu">
                    <img :src="cMenu.img" />
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <div @click="menuClick($router, menu)" :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))" v-for="(menu, index) in liveSupportMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)" :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)" :src="menu.selectedImg" />
              </div>
              <div class="menu-item-text" v-if="menu.code">
                {{ t(menu.code) }}
              </div>
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
              <div v-if="menu.childRen" v-show="menu.show" class="menu-item-children" style="clear: both">
                <div v-for="(cMenu, cIndex) in menu.childRen" @click="menuClick($router, cMenu.path)" :key="'index' + cIndex" style="float: left; margin: 0.2rem">
                  <div class="children-menu">
                    <img :src="cMenu.img" />
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div> -->

          <div style="clear: both; margin-bottom: 0.6rem"></div>

          <div :class="'menu'">
            <my-i18n :showMenu="showMenu" @showMenuChange="
              changeShow();
            showMenuChange();
            "></my-i18n>
            <div style="clear: both"></div>
          </div>
          <div style="clear: both; margin-bottom: 3rem"></div>
        </div>
      </div>
    </transition>
    <transition name="slide-fade2">
      <div class="" style="width: 100%" v-if="!show">
        <div :class="'menu menu2 menu-body scrollbar'" id="all_menu2">
          <div :class="'menu menu2'">
            <img v-show="gameCategory == 'Game'" class="img-item" :src="bGameImg1"
              @click="gameCategoryChange('Game')" />
            <img v-show="gameCategory !== 'Game'" class="img-item" :src="bGameImg2"
              @click="gameCategoryChange('Game')" />
          </div>
          <div :class="'menu menu2'">
            <img v-show="gameCategory == 'Sports'" class="img-item" :src="bSportsImg1"
              @click="gameCategoryChange('Sports')" />
            <img v-show="gameCategory !== 'Sports'" class="img-item" :src="bSportsImg2"
              @click="gameCategoryChange('Sports')" />
          </div>
          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu menu2'">
            <div
              :class="'menu-item ' + (menu.auth && !isLogin ? 'not-allowed ' : '') + (isSelected($router, menu.path) ? 'menu-selected' : '')"
              v-for="(menu, index) in menus" :key="index" @click="menuClick($router, menu)"
              @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)"
                  :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)"
                  :src="menu.selectedImg" />
              </div>
              <!--          <div class="menu-item-text" v-if="menu.code">-->
              <!--            {{t(menu.code)}}-->
              <!--          </div>-->
              <div class="menu-item-right" v-if="menu.icon">
                <img :src="menu.icon" />
              </div>
            </div>

            <div style="clear: both"></div>
          </div>
          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu menu2'">
            <div
              :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))"
              v-for="(menu, index) in gameMenus" :key="index" v-show="!menu.isHidden" @click="menuClick($router, menu)"
              @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)"
                  :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)"
                  :src="menu.selectedImg" />
              </div>
              <div class="menu-item-right" v-show="menu.icon">
                <img :src="menu.icon" />
              </div>
              <div v-if="menu.childRen" v-show="menu.show" class="menu-item-children" style="clear: both">
                <div v-for="(cMenu, cIndex) in menu.childRen" @click="menuClick($router, cMenu.path)"
                  :key="'index' + cIndex" style="float: left; margin: 0.2rem">
                  <div class="children-menu">
                    <img :src="cMenu.img" />
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <!-- 新UI-移动端菜单-开始 -->
          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu menu2'">
            <div
              :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))"
              v-for="(menu, index) in moreMenus" :key="index" v-show="!menu.isHidden" @click="menuClick($router, menu)"
              @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)"
                  :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)"
                  :src="menu.selectedImg" />
              </div>
              <div class="menu-item-right" v-show="menu.icon">
                <img :src="menu.icon" />
              </div>
              <div v-if="menu.childRen" v-show="menu.show" class="menu-item-children" style="clear: both">
                <div v-for="(cMenu, cIndex) in menu.childRen" @click="menuClick($router, cMenu.path)"
                  :key="'index' + cIndex" style="float: left; margin: 0.2rem">
                  <div class="children-menu">
                    <img :src="cMenu.img" />
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <!-- 新UI-移动端菜单-结束 -->
          <!-- <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <div @click="menuClick($router, menu)" :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))" v-for="(menu, index) in helpMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)" :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)" :src="menu.selectedImg" />
              </div>
              <div class="menu-item-right" v-show="menu.icon">
                <img :src="menu.icon" />
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <div @click="menuClick($router, menu)" :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))" v-for="(menu, index) in providerMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)" :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)" :src="menu.selectedImg" />
              </div>
              <div class="menu-item-right" v-show="menu.icon">
                <img :src="menu.icon" />
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <div @click="menuClick($router, menu)" :class="'menu-item ' + (isSelected($router, menu.path) ? 'menu-selected' : '' + (menu.childRen ? ' ' : ''))" v-for="(menu, index) in liveSupportMenus" :key="index" v-show="!menu.isHidden" @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <div class="menu-item-img" :title="menu.name">
                <img v-show="(menu.auth && !isLogin) || !(isSelected($router, menu.path) || menu.hover)" :src="menu.img" />
                <img v-show="(isSelected($router, menu.path) || menu.hover) && !(menu.auth && !isLogin)" :src="menu.selectedImg" />
              </div>
              <div class="menu-item-right" v-show="menu.icon">
                <img :src="menu.icon" />
              </div>
            </div>
            <div style="clear: both"></div>
          </div> -->

          <div style="clear: both; margin-bottom: 0.6rem"></div>
          <div :class="'menu'">
            <my-i18n :showMenu="showMenu" @showMenuChange="
              changeShow();
            showMenuChange();
            "></my-i18n>
            <div style="clear: both"></div>
          </div>
          <div style="clear: both; margin-bottom: 3rem"></div>
        </div>
      </div>
    </transition>
  </div>

  <div class="phone my-footer-menu disableSelection">
    <div class="main-content">
      <div class="body footer-menu-item">
        <div v-for="(menu, index) in footerMenus" @click="jump(menu)"
          :class="(isSelected($router, menu.path) && '/' == menu.path) || ('/' == menu.path && phone_show_menu) || (!phone_show_menu && '/' !== menu.path && isSelected($router, menu.path)) ? 'footer-menu-selected' : ''"
          :key="index">
          <div style="width: 100%; height: 4.25rem; align-items: center">
            <div class="footer-icon">
              <img
                :src="(isSelected($router, menu.path) && '/' == menu.path) || ('/' == menu.path && phone_show_menu) || (!phone_show_menu && '/' !== menu.path && isSelected($router, menu.path)) ? menu.selectedImg : menu.img" />
            </div>
            <div style="height: 1.25rem">{{ t(menu.menuCode) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <turntableDialog v-model="appStore.sportsShow" :initiativeTab="activeInitiativeTab" :list="activeList"></turntableDialog> -->
</template>

<style scoped>
.show .menu.menu-header .img {
  width: calc(49% - 0.5rem);
  height: auto;
}

.show .menu.menu-header .img img {
  width: 95%;
  height: 3rem;
}

.menu.menu-header .img .menu-header-name {
  height: 2.25rem;
  width: 5.275rem;
  text-align: center;
  color: #ffffff;
  margin-top: -2.45rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show .menu.menu-header .img .menu-header-name {
  width: 95%;
  padding: 0 0.2rem;
  margin-top: -3.15rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone .show.scrollbar-width-thin {
  background: #0e1224;
}

.phone .show .menu.menu-header {
  top: 4rem;
  background: #0e1224;
}

.phone .main-content.phone_hidden {
  display: block;
}

svg.selected {
  fill: #fff;
  stroke: #fff;
}

.scrollbar.menu-body {
  padding-right: 0.3rem;
}
</style>
