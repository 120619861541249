<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
    list: {
        type: Array,
        default: () => []
    },
    value: {
        type: String
    }
})

const emits = defineEmits(['change'])

const index = computed(() => {
    const index = props.list.findIndex(item => {
        return item === props.value
    })
    return index
})



const switchWeekTime = (type) => {
    console.log(index.value)
    if (type === 'last' && index.value - 1 >= 0) {
        emits('change', props.list[index.value - 1])
    }
    if (type === 'next' && index.value + 1 < props.list.length) {
        emits('change', props.list[index.value + 1])
    }
}



</script>

<template>
    <div class="flex justify-between items-center">
        <div class="bg-[#222942] w-[3.25rem] h-[2.5rem] flex justify-center items-center rounded-md cursor-pointer"
            @click="switchWeekTime('last')" :disable="true">
            <el-icon color="#80A4DB">
                <Back />
            </el-icon>
        </div>
        <div class="bg-[#0D101E] px-[1rem] py-[0.75rem] text-[0.875rem] font-bold mx-[1rem] text-[#fff]">
            {{ props.value === '' ? t('all') : props.value }}
        </div>
        <div class="bg-[#222942] w-[3.25rem] h-[2.5rem] flex justify-center items-center rounded-md cursor-pointer"
            @click="switchWeekTime('next')">
            <el-icon color="#80A4DB">
                <Right />
            </el-icon>
        </div>
    </div>
</template>